/* ==========================================================================
   #HEADER
   ========================================================================== */

.custom-logo-link {
  display: block;

  @include bp-down(medium) {
    max-width: 115px;
  }

  img {
    min-width: 160px;
  }
}

.c-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-blue;
    transform: translateY(-100%);
    transition: transform 0.3s $menu-transition;
    z-index: 0;
  }

  &.scrolled:after {
    transform: translateY(0);
  }
}

body:not(.home) .c-header {
  @extend .scrolled;
}

.c-header__inner {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 10px;
  transition: padding-top 0.3s $menu-transition;
  z-index: 1;

  @include bp(medium) {
    padding-top: 25px;
    padding-bottom: 15px;
  }

  &:after {
    display: none !important;
  }
}

.scrolled .c-header__inner {
  padding-top: 15px;
}

.c-header__right {
  display: flex;
  align-items: center;

  >.c-btn--dark {
    margin-right: 30px;

    @include bp(medium) {
      margin-right: 90px;
    }
  }
}

.c-header__search {
  position: relative;
  display: none;
  min-width: 30px;

  @include bp(medium) {
    display: block;
    margin-right: 40px;
  }

  &:hover {
    .c-header__search-input {
      width: 275px;
      margin-right: 0;
    }
  }
}

.c-header__search-icon {
  position: absolute;
  right: 0;
  top: 50%;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  transform: translateY(-50%);

  svg {
    width: 15px;
    height: 15px;

    path, circle {
      transition: $global-transition;
    }
  }

  &:hover {
    svg {
      path {
        fill: white;
      }

      circle {
        stroke: white;
      }
    }
  }
}

.c-header__search-input {
  width: 0;
  margin-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
  outline: none;
  background: none;
  font-size: 13px;
  font-family: $font-serif;
  overflow: hidden;
  border-bottom: 1px solid black;
  transition: $global-transition;
}

.c-header__search-input::placeholder {
  font-style: italic;
  color: $color-text;
}

.c-header__search-input::-moz-placeholder {
  font-style: italic;
  color: $color-text;
}

.c-header__search-input::-webkit-input-placeholder {
  font-style: italic;
  color: $color-text;
}
