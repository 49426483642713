.c-why {
  padding-top: 75px;
  padding-bottom: 85px;
}

.c-why__content,
.c-why__title {
  text-align: center;
}

.c-why__content {
  margin-bottom: 40px;
}
