.c-popular-posts {
  .c-posts-scroller__title {
    @include bp-down(large) {
      margin-bottom: 10px;
    }
  }

  .c-popular-posts__list-col-1 {
    @include bp(large) {
      width: 35.5%;
    }
  }

  .c-popular-posts__list-col-2 {
    margin-top: 10px;

    @include bp(large) {
      width: 44.5%;
      margin-top: 0;
      padding: 0 20px;
    }
  }

  .c-popular-posts__list-col-3 {
    margin-top: 10px;

    @include bp(large) {
      width: 20%;
      margin-top: 0;
    }
  }

  .c-tease__category--popular,
  .c-tease__desc {
    display: none;
  }
}

.c-popular-posts__list-col-1 {
  .c-tease {
    height: 100%;
  }

  .c-tease__popular {
    @include bp(large) {
      padding: 35px 55px;
    }

    h4 {
      @include bp-down(large) {
        font-size: 24px;
      }
    }
  }

  .c-tease__popular:before {
    @include bp-down(large) {
      display: block;
      width: 30%;
    }
  }

  .c-tease__category--popular,
  .c-tease__desc {
    display: block;
  }
}

.c-recent-posts__list-col.c-popular-posts__list-col-2 {
  display: flex;
  flex-wrap: wrap;

  .c-tease {
    width: 50%;
  }

  .c-tease:first-child {
    width: 100%;
    margin-bottom: 10px;

    @include bp(large) {
      margin-bottom: 20px;
    }

    .c-tease__popular {
      @include bp-down(large) {
        padding-top: 40px;
      }
    }
  }

  .c-tease:nth-child(2) {
    padding-right: 5px;

    @include bp(large) {
      padding-right: 10px;
    }
  }

  .c-tease:nth-child(3) {
    padding-left: 5px;

    @include bp(large) {
      padding-left: 10px;
    }
  }

  .c-tease:nth-child(2),
  .c-tease:nth-child(3) {
    h4 {
      font-size: 16px;

      @include bp(large) {
        font-size: 21px;
      }
    }

    .c-tease__popular {
      padding-bottom: 45px;
    }

    .c-tease__timestamp {
      .divider {
        display: none;
      }

      .views {
        display: block;
      }
    }

    .c-tease__author {
      @include bp-down(large) {
        font-size: 10px;
        letter-spacing: 1px;
      }
    }
  }
}

.c-recent-posts__list-col.c-popular-posts__list-col-3 {
  @include bp-down(large) {
    display: flex;
    align-items: stretch;

    .c-tease:nth-child(1) {
      padding-right: 5px;
    }

    .c-tease:nth-child(2) {
      padding-left: 5px;
    }

    h4 {
      font-size: 16px;
    }

    .c-tease__author {
      font-size: 10px;
      letter-spacing: 1px;
    }
  }

  .c-tease__timestamp {
    .divider {
      display: none;
    }

    .views {
      display: block;
    }
  }

  .c-tease:first-child {
    @include bp(large) {
      margin-bottom: 20px;
    }
  }

  .c-tease h4 {
    @include bp(large) {
      font-size: 21px;
    }
  }

  .c-tease__popular {
    padding-bottom: 45px;
  }
}
