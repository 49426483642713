@charset "UTF-8";
/*
  Project: Daliel
  Author: Marcin Ziółek
 */
/* Fonts
   ========================================================================== */
@import url("https://fonts.googleapis.com/css?family=Raleway:400,700|Zilla+Slab:400,400i,500,700&display=swap");
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.c-color--recent,
.c-color--recent .c-main-nav__link {
  background-color: #171b27;
  color: white;
  border: none;
}

@media (min-width: 1024px) {
  .c-color--recent,
  .c-color--recent .c-main-nav__link {
    font-size: 20px;
  }
}

.c-color--recent.current-menu-item .c-main-nav__link {
  color: #f7f7f7;
  background-color: #171b27;
}

.c-color--recent .c-main-nav__link {
  padding-left: 60px;
  background: url("../assets/images/recent-icon-white.png") no-repeat left 15px center;
}

@media (min-width: 1024px) {
  .c-color--recent .c-main-nav__link {
    padding-left: 85px;
    background-position: left 25px center;
  }
}

.c-color--recent .c-main-nav__link:hover {
  color: #f7f7f7;
  background-color: #171b27;
}

.c-color--popular,
.c-color--popular .c-main-nav__link {
  background-color: #f7f7f7;
  color: #171b27;
  border: none;
}

@media (min-width: 1024px) {
  .c-color--popular,
  .c-color--popular .c-main-nav__link {
    font-size: 20px;
  }
}

.c-color--popular.current-menu-item .c-main-nav__link {
  color: #333;
  background-color: #f7f7f7;
}

.c-color--popular .c-main-nav__link {
  padding-left: 60px;
  background: url("../assets/images/popular-icon.png") no-repeat left 15px center;
}

@media (min-width: 1024px) {
  .c-color--popular .c-main-nav__link {
    padding-left: 85px;
    background-position: left 25px center;
  }
}

.c-color--popular .c-main-nav__link:hover {
  color: #333;
  background-color: #f7f7f7;
}

.c-color--politics,
.c-color--politics .c-main-nav__link {
  background-color: #02adf3;
  color: white;
  border: none;
}

@media (min-width: 1024px) {
  .c-color--politics,
  .c-color--politics .c-main-nav__link {
    font-size: 20px;
  }
}

.c-color--politics.current-menu-item .c-main-nav__link {
  color: #f7f7f7;
  background-color: #02adf3;
}

.c-color--politics .c-main-nav__link {
  padding-left: 60px;
  background: url("../assets/images/politics-icon-white.png") no-repeat left 15px center;
}

@media (min-width: 1024px) {
  .c-color--politics .c-main-nav__link {
    padding-left: 85px;
    background-position: left 25px center;
  }
}

.c-color--politics .c-main-nav__link:hover {
  color: #f7f7f7;
  background-color: #02adf3;
}

.c-color--identity,
.c-color--identity .c-main-nav__link {
  background-color: #00cdf2;
  color: white;
  border: none;
}

@media (min-width: 1024px) {
  .c-color--identity,
  .c-color--identity .c-main-nav__link {
    font-size: 20px;
  }
}

.c-color--identity.current-menu-item .c-main-nav__link {
  color: #f7f7f7;
  background-color: #00cdf2;
}

.c-color--identity .c-main-nav__link {
  padding-left: 60px;
  background: url("../assets/images/identity-icon-white.png") no-repeat left 15px center;
}

@media (min-width: 1024px) {
  .c-color--identity .c-main-nav__link {
    padding-left: 85px;
    background-position: left 25px center;
  }
}

.c-color--identity .c-main-nav__link:hover {
  color: #f7f7f7;
  background-color: #00cdf2;
}

.c-color--theology,
.c-color--theology .c-main-nav__link {
  background-color: #00d8df;
  color: white;
  border: none;
}

@media (min-width: 1024px) {
  .c-color--theology,
  .c-color--theology .c-main-nav__link {
    font-size: 20px;
  }
}

.c-color--theology.current-menu-item .c-main-nav__link {
  color: #f7f7f7;
  background-color: #00d8df;
}

.c-color--theology .c-main-nav__link {
  padding-left: 60px;
  background: url("../assets/images/theology-icon-white.png") no-repeat left 15px center;
}

@media (min-width: 1024px) {
  .c-color--theology .c-main-nav__link {
    padding-left: 85px;
    background-position: left 25px center;
  }
}

.c-color--theology .c-main-nav__link:hover {
  color: #f7f7f7;
  background-color: #00d8df;
}

.c-color--history,
.c-color--history .c-main-nav__link {
  background-color: #313649;
  color: white;
  border: none;
}

@media (min-width: 1024px) {
  .c-color--history,
  .c-color--history .c-main-nav__link {
    font-size: 20px;
  }
}

.c-color--history.current-menu-item .c-main-nav__link {
  color: #f7f7f7;
  background-color: #313649;
}

.c-color--history .c-main-nav__link {
  padding-left: 60px;
  background: url("../assets/images/history-icon-white.png") no-repeat left 15px center;
}

@media (min-width: 1024px) {
  .c-color--history .c-main-nav__link {
    padding-left: 85px;
    background-position: left 25px center;
  }
}

.c-color--history .c-main-nav__link:hover {
  color: #f7f7f7;
  background-color: #313649;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, .c-post__tagline, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
address,
h1, h2, h3, .c-post__tagline, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 15px;
}

dd, ol, ul {
  margin: 0;
}

p:last-child {
  margin-bottom: 0;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: '“' '”' '‘' '’';
  font-style: italic;
}

blockquote p:first-child:before {
  content: open-quote;
}

blockquote p:last-child:after {
  content: close-quote;
}

body {
  padding-top: 79px;
}

@media (min-width: 768px) {
  body {
    padding-top: 83px;
  }
}

/* stylelint-disable */
.gform_fields {
  display: block;
  margin: 0;
  list-style: none;
}

.gform_validation_container {
  display: none !important;
  position: absolute !important;
  left: -9000px;
}

.gfield_label {
  display: none;
}

input,
textarea,
select {
  border: none;
  outline: none;
  border-radius: 0;
}

.gfield {
  position: relative;
}

.gfield input,
.gfield textarea,
.gfield select {
  display: block;
  width: 100%;
  padding: 8px 20px;
  font-family: "Raleway", sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif;
  font-size: 13px;
  font-weight: bold;
}

@media (min-width: 768px) {
  .gfield input,
  .gfield textarea,
  .gfield select {
    padding: 18px 20px;
  }
}

.gfield input::placeholder {
  font-family: "Raleway", sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif;
  color: #171b27;
}

.gfield input::-webkit-placeholder {
  font-family: "Raleway", sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif;
  color: #171b27;
}

.gfield input::-moz-placeholder {
  font-family: "Raleway", sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif;
  color: #171b27;
}

.gfield [type="email"] {
  height: 50px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

@media (max-width: 767.98px) {
  .gfield [type="email"] {
    height: 30px;
  }
}

.gform_button {
  height: 50px;
  border: none;
  border-radius: 0;
  outline: none;
}

@media (max-width: 767.98px) {
  .gform_button {
    height: 30px;
  }
}

@media (min-width: 768px) {
  input.gform_button {
    font-size: 13px;
    padding-top: 17px;
    padding-bottom: 17px;
  }
}

.gform_footer {
  position: relative;
}

.gform_ajax_spinner {
  position: absolute;
  right: -25px;
  top: 20px;
}

.validation_message {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

/* stylelint-enable */
section {
  max-width: 100%;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-weight: 500;
  font-size: 34px;
}

@media (min-width: 1024px) {
  h1 {
    font-size: 48px;
  }
}

@media (min-width: 1200px) {
  h1 {
    font-size: 63px;
  }
}

h2 {
  font-size: 21px;
}

@media (min-width: 1024px) {
  h2 {
    font-size: 38px;
  }
}

@media (min-width: 1200px) {
  h2 {
    font-size: 48px;
  }
}

h3, .c-post__tagline {
  font-size: 19px;
}

@media (min-width: 1024px) {
  h3, .c-post__tagline {
    font-size: 30px;
  }
}

@media (min-width: 1200px) {
  h3, .c-post__tagline {
    font-size: 36px;
  }
}

h4 {
  font-size: 21px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

h1,
h2, h3, .c-post__tagline,
h4,
h5,
h6 {
  font-family: "Zilla Slab", serif;
  line-height: 1;
}

h1 a,
h2 a,
h3 a,
.c-post__tagline a,
h4 a,
h5 a,
h6 a {
  text-decoration: none;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 */
html {
  background: #f7f7f7;
  color: #171b27;
  font-family: "Raleway", sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif;
  font-size: 19.2px;
  line-height: 1.5;
  min-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@media (min-width: 768px) {
  html {
    font-size: 14px;
  }
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  height: auto;
  vertical-align: middle;
  /* [3] */
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #171b27;
  text-decoration: underline;
}

a:not(.c-btn):not(.gform_button) {
  transition: all 300ms ease-in-out;
}

a:hover {
  color: #00cdf2;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* scrollbar */
/* stylelint-disable */
[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  width: auto;
  visibility: visible;
  overflow: auto;
  max-width: 100%;
  max-height: 100%;
}

.simplebar-content:after,
.simplebar-content:before {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: inherit;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 7px;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: #00cdf2;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-track .simplebar-scrollbar.simplebar-visible:before {
  opacity: 1;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  width: auto;
  height: 11px;
  left: 20px;
  right: 20px;
}

@media (min-width: 1200px) {
  .simplebar-track.simplebar-horizontal {
    width: 100%;
    max-width: 1170px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 6px;
  min-height: 0;
  min-width: 10px;
  width: auto;
  z-index: 1;
}

[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

/* stylelint-enable */
.simplebar-track:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  width: 100%;
  height: 2px;
  margin-top: -1px;
  background-color: #e3e3e3;
  z-index: 0;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}

@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout__item-auto {
  flex: 0 0 auto;
}

.o-layout--guttered {
  justify-content: space-between;
}

.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}

.o-media:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}

.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.o-wrapper:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.o-wrapper--narrow {
  max-width: 1024px;
}

.o-wrapper--supernarrow {
  max-width: 860px;
}

.c-about {
  position: relative;
  padding-top: 60px;
  padding-bottom: 30px;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .c-about {
    padding-top: 230px;
    padding-bottom: 130px;
  }
}

.c-about__content {
  position: relative;
  max-width: 90%;
  margin-left: auto;
}

@media (min-width: 1024px) {
  .c-about__content {
    max-width: 45%;
  }
}

.c-about__content-bg {
  content: '';
  position: absolute;
  top: -35px;
  bottom: -50px;
  left: -80px;
  right: 0;
  display: block;
  background-repeat: repeat;
  background-position: left top;
  z-index: 0;
}

@media (min-width: 1024px) {
  .c-about__content-bg {
    left: -100px;
    top: -160px;
    right: -999px;
    right: -30vw;
  }
}

.c-about__content-inner {
  position: relative;
  padding-right: 15px;
  font-size: 15px;
  z-index: 1;
}

@media (min-width: 1024px) {
  .c-about__content-inner {
    padding-right: 0;
  }
}

.c-about__content-inner .c-btn, .c-about__content-inner .gform_button, .c-about__content-inner .page-contact .c-page__content .gform_button, .page-contact .c-page__content .c-about__content-inner .gform_button, .c-about__content-inner .c-donation__content .gfield_radio li label, .c-donation__content .gfield_radio li .c-about__content-inner label, .c-about__content-inner .c-post__nav a, .c-post__nav .c-about__content-inner a, .c-about__content-inner .c-work a:not(.c-work__social), .c-work .c-about__content-inner a:not(.c-work__social) {
  margin-top: 15px;
}

.c-about__subtitle {
  font-family: "Zilla Slab", serif;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 2px;
  margin-left: -27px;
}

@media (min-width: 1024px) {
  .c-about__subtitle {
    margin-left: 0;
  }
}

.c-about__subtitle:before {
  content: '';
  display: inline-block;
  width: 24px;
  height: 9px;
  margin-right: 6px;
  margin-bottom: 2px;
  vertical-align: middle;
  background-color: #00cdf2;
}

.c-about__subtitle span {
  vertical-align: middle;
}

.c-about__title {
  margin-bottom: 25px;
}

.c-about__img {
  display: block;
}

@media (min-width: 1024px) {
  .c-about__img {
    position: absolute;
  }
}

.c-about__img--1 {
  width: 90%;
}

@media (min-width: 1024px) {
  .c-about__img--1 {
    bottom: 65px;
    left: 0;
    width: auto;
  }
}

.c-about__img--2 {
  width: 50%;
  margin-left: auto;
  margin-top: -80px;
}

@media (min-width: 1024px) {
  .c-about__img--2 {
    bottom: 0;
    right: 50%;
    width: auto;
    margin-left: 0;
    margin-top: 0;
  }
}

.c-archive {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 30px -20px 0;
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
.c-btn, .gform_button, .page-contact .c-page__content .gform_button, .c-donation__content .gfield_radio li label, .c-page__donate-form .gform_button, .c-post__nav a, .c-work a:not(.c-work__social) {
  display: inline-block;
  margin: 0;
  padding: 10px 20px;
  vertical-align: middle;
  font: inherit;
  font-family: "Zilla Slab", serif;
  font-weight: bold;
  line-height: 1.3;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

/* Style variants
   ========================================================================== */
.c-btn--primary, .page-contact .c-page__content .gform_button, .c-donation__content .gform_button, .c-page__donate-form .gform_button, .c-post__nav a {
  background-color: #00cdf2;
}

.c-btn--primary, .page-contact .c-page__content .gform_button, .c-donation__content .gform_button, .c-page__donate-form .gform_button, .c-post__nav a, .c-btn--primary:hover, .page-contact .c-page__content .gform_button:hover, .c-donation__content .gform_button:hover, .c-page__donate-form .gform_button:hover, .c-post__nav a:hover, .c-btn--primary:active, .page-contact .c-page__content .gform_button:active, .c-donation__content .gform_button:active, .c-page__donate-form .gform_button:active, .c-post__nav a:active, .c-btn--primary:focus, .page-contact .c-page__content .gform_button:focus, .c-donation__content .gform_button:focus, .c-page__donate-form .gform_button:focus, .c-post__nav a:focus {
  text-decoration: none;
  /* [4] */
  color: #171b27;
}

.c-btn--primary:hover, .page-contact .c-page__content .gform_button:hover, .c-donation__content .gform_button:hover, .c-page__donate-form .gform_button:hover, .c-post__nav a:hover, .c-btn--primary:focus, .page-contact .c-page__content .gform_button:focus, .c-donation__content .gform_button:focus, .c-page__donate-form .gform_button:focus, .c-post__nav a:focus {
  background-color: white;
}

.c-btn--dark, .gform_button {
  padding: 8px 20px;
  font-size: 11px;
  background-color: #171b27;
}

@media (min-width: 768px) {
  .c-btn--dark, .gform_button {
    padding: 14px 30px;
    font-size: 13px;
  }
}

.c-btn--dark, .gform_button, .c-btn--dark:hover, .gform_button:hover, .c-btn--dark:active, .gform_button:active, .c-btn--dark:focus, .gform_button:focus {
  text-decoration: none;
  /* [4] */
  color: white;
}

.c-btn--dark:hover, .gform_button:hover, .c-btn--dark:focus, .gform_button:focus {
  background-color: white;
  color: #171b27;
}

.c-btn--outline, .c-donation__content .gfield_radio li label, .c-work a:not(.c-work__social) {
  padding: 16px 38px;
  background-color: transparent;
  border: 3px solid white;
}

.c-btn--outline:hover, .c-donation__content .gfield_radio li label:hover, .c-work a:hover:not(.c-work__social) {
  background-color: white;
  color: #171b27;
}

.c-btn--outline-dark, .c-page__donate .c-donation__content .gfield_radio label {
  background-color: transparent;
  border: 3px solid #171b27;
}

.c-btn--outline-dark:hover, .c-page__donate .c-donation__content .gfield_radio label:hover {
  background-color: #171b27;
  color: white;
}

.c-btn--dark-blue {
  padding: 17px 20px;
  font-size: 13px;
  color: #00cdf2;
  background-color: #171b27;
}

.c-btn--dark-blue, .c-btn--dark-blue:hover, .c-btn--dark-blue:active, .c-btn--dark-blue:focus {
  text-decoration: none;
  /* [4] */
  color: #00cdf2;
}

.c-btn--dark-blue:hover, .c-btn--dark-blue:focus {
  background-color: #fff;
  color: #00cdf2;
}

.c-btn--read {
  padding: 8px 15px;
  background-color: #00cdf2;
  color: white;
  font-size: 13px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .c-btn--read {
    padding: 12px 18px;
  }
}

.c-btn--read:hover, .c-btn--read:focus {
  color: white;
  background-color: #171b27;
}

.c-btn--read:hover span, .c-btn--read:focus span {
  transform: translateX(50%);
  opacity: 0;
}

.c-btn--read:hover:before, .c-btn--read:focus:before {
  transform: translateX(-50%);
  opacity: 1;
}

.c-btn--read:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-100%);
  display: block;
  width: 21px;
  height: 12px;
  margin-top: -6px;
  background: url("../assets/images/arrow-icon.png") no-repeat center center;
  background-size: 21px 12px;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
}

.c-btn--read span {
  display: block;
  opacity: 1;
  transform: translateX(0);
  transition: all 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
}

.c-btn--read-dark {
  color: #171b27;
}

@media (min-width: 768px) {
  .c-btn--read-dark {
    padding: 22px 20px;
  }
}

.c-btn--read-dark:before {
  background: url("../assets/images/arrow-icon-dark.png") no-repeat center center;
  background-size: 21px 12px;
}

.c-btn--read-dark:hover, .c-btn--read-dark:focus {
  color: #171b27;
  background-color: white;
}

.c-btn--read-white {
  color: #171b27;
  background-color: white;
}

.c-btn--read-white:before {
  background: url("../assets/images/arrow-icon.png") no-repeat center center;
  background-size: 21px 12px;
}

.c-btn--read-white:hover, .c-btn--read-white:focus {
  color: #171b27;
  background-color: #00cdf2;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.card-body {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 1024px) {
  .card-body {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.c-career .o-wrapper {
  max-width: 1000px;
}

.c-career a {
  color: #00cdf2;
}

.c-career a:hover {
  color: #171b27;
}

.c-career .c-btn, .c-career .gform_button, .c-career .page-contact .c-page__content .gform_button, .page-contact .c-page__content .c-career .gform_button, .c-career .c-donation__content .gfield_radio li label, .c-donation__content .gfield_radio li .c-career label, .c-career .c-post__nav a, .c-post__nav .c-career a, .c-career .c-work a:not(.c-work__social), .c-work .c-career a:not(.c-work__social) {
  width: 100%;
  margin-top: 15px;
  text-align: left;
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 0;
  text-transform: none;
  color: #171b27;
}

@media (min-width: 1024px) {
  .c-career .c-btn, .c-career .gform_button, .c-career .page-contact .c-page__content .gform_button, .page-contact .c-page__content .c-career .gform_button, .c-career .c-donation__content .gfield_radio li label, .c-donation__content .gfield_radio li .c-career label, .c-career .c-post__nav a, .c-post__nav .c-career a, .c-career .c-work a:not(.c-work__social), .c-work .c-career a:not(.c-work__social) {
    margin-top: 30px;
    font-size: 36px;
  }
}

.c-career .c-btn--blue {
  background-color: #00cdf2;
}

.c-career .c-btn--water {
  background-color: #02adf3;
}

.c-career .c-btn--grey {
  background-color: #171b27;
  color: white;
}

.c-career .c-btn--mint {
  background-color: #00d8df;
}

.c-columns {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .c-columns {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.c-columns__item {
  width: 230px;
  text-align: center;
  font-size: 19.2px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.c-columns__item:last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .c-columns__item {
    margin: 0;
  }
}

.c-columns__item--auto {
  width: auto;
  text-align: left;
}

.c-columns__title {
  margin-bottom: 10px;
  margin-top: 25px;
  text-transform: uppercase;
  color: #00cdf2;
  font-family: "Zilla Slab", serif;
}

/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */
.c-comment-form {
  margin-top: 2em;
}

.c-comment-form label {
  display: block;
}

.c-comment-form textarea {
  max-width: 100%;
}

.comment-reply-title {
  margin-bottom: 0.25em;
}

/* ==========================================================================
   #COMMENT
   ========================================================================== */
.c-comment {
  margin-bottom: 1em;
}

.c-comment__author {
  font-size: 1rem;
  margin-bottom: 0.25em;
}

.c-comment__moderation {
  font-style: italic;
  margin-bottom: 0.5em;
}

.page-contact .c-page__hero {
  text-align: center;
}

.page-contact .c-page__content {
  padding-top: 0;
}

.page-contact .c-page__content .o-wrapper {
  max-width: 1000px;
}

.page-contact .c-page__content .gform_wrapper {
  max-width: 850px;
  margin: 0 auto;
  padding: 15px;
  background-color: white;
}

@media (min-width: 1024px) {
  .page-contact .c-page__content .gform_wrapper {
    padding: 30px 65px;
  }
}

.page-contact .c-page__content .gform_title {
  text-transform: uppercase;
}

.page-contact .c-page__content .gform_body {
  margin-top: 15px;
}

@media (min-width: 768px) {
  .page-contact .c-page__content .gform_body {
    margin-top: 30px;
  }
}

.page-contact .c-page__content .gfield {
  margin-bottom: 10px;
}

.page-contact .c-page__content .gfield:nth-child(1), .page-contact .c-page__content .gfield:nth-child(2) {
  max-width: 300px;
}

.page-contact .c-page__content .gfield input,
.page-contact .c-page__content .gfield textarea {
  border-radius: 2px;
  border: 1px solid #e3e3e5;
  background-color: #f7f7f7;
}

.page-contact .c-page__content .gform_footer:after {
  content: '*Verplichte velden';
  display: block;
  margin-top: 10px;
  font-size: 13px;
}

.c-featured-news {
  padding-bottom: 40px;
}

@media (max-width: 767.98px) {
  .c-featured-news {
    padding-top: 40px;
  }
}

.c-featured-news .c-tease {
  width: 100%;
  margin: 0;
}

@media (min-width: 768px) {
  .c-featured-news .c-tease {
    display: flex;
  }
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  padding: 8px 0;
  background-color: #171b27;
}

.c-footer__menu {
  display: block;
  list-style: none;
  text-align: center;
}

.c-footer__menu-item {
  display: inline-block;
  margin: 0;
}

@media (min-width: 768px) {
  .c-footer__menu-item {
    margin: 0 10px;
  }
}

.c-footer__menu-link {
  display: block;
  padding: 5px;
  font-family: "Zilla Slab", serif;
  font-weight: bold;
  color: #00cdf2;
  text-transform: uppercase;
  text-decoration: none;
}

.c-footer__menu-link:hover {
  color: white;
}

@media (min-width: 768px) {
  .c-footer__menu-link {
    padding: 5px 10px;
  }
}

@media (max-width: 767.98px) {
  .c-footer__menu-link {
    font-size: 11px;
  }
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.custom-logo-link {
  display: block;
}

@media (max-width: 767.98px) {
  .custom-logo-link {
    max-width: 115px;
  }
}

.custom-logo-link img {
  min-width: 160px;
}

.c-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.c-header:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00cdf2;
  transform: translateY(-100%);
  transition: transform 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
  z-index: 0;
}

.c-header.scrolled:after, body:not(.home) .c-header:after, .single .c-header:after {
  transform: translateY(0);
}

.c-header__inner {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 10px;
  transition: padding-top 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
  z-index: 1;
}

@media (min-width: 768px) {
  .c-header__inner {
    padding-top: 25px;
    padding-bottom: 15px;
  }
}

.c-header__inner:after {
  display: none !important;
}

.scrolled .c-header__inner, body:not(.home) .c-header .c-header__inner, .single .c-header .c-header__inner {
  padding-top: 15px;
}

.c-header__right {
  display: flex;
  align-items: center;
}

.c-header__right > .c-btn--dark, .c-header__right > .gform_button {
  margin-right: 30px;
}

@media (min-width: 768px) {
  .c-header__right > .c-btn--dark, .c-header__right > .gform_button {
    margin-right: 90px;
  }
}

.c-header__search {
  position: relative;
  display: none;
  min-width: 30px;
}

@media (min-width: 768px) {
  .c-header__search {
    display: block;
    margin-right: 40px;
  }
}

.c-header__search:hover .c-header__search-input {
  width: 275px;
  margin-right: 0;
}

.c-header__search-icon {
  position: absolute;
  right: 0;
  top: 50%;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  transform: translateY(-50%);
}

.c-header__search-icon svg {
  width: 15px;
  height: 15px;
}

.c-header__search-icon svg path, .c-header__search-icon svg circle {
  transition: all 300ms ease-in-out;
}

.c-header__search-icon:hover svg path {
  fill: white;
}

.c-header__search-icon:hover svg circle {
  stroke: white;
}

.c-header__search-input {
  width: 0;
  margin-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
  outline: none;
  background: none;
  font-size: 13px;
  font-family: "Zilla Slab", serif;
  overflow: hidden;
  border-bottom: 1px solid black;
  transition: all 300ms ease-in-out;
}

.c-header__search-input::placeholder {
  font-style: italic;
  color: #171b27;
}

.c-header__search-input::-moz-placeholder {
  font-style: italic;
  color: #171b27;
}

.c-header__search-input::-webkit-input-placeholder {
  font-style: italic;
  color: #171b27;
}

.c-hero {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}

@media (max-width: 767.98px) {
  .c-hero {
    padding-top: 0 !important;
  }
}

@media (min-width: 600px) {
  .c-hero {
    background-position: center bottom;
  }
}

.c-hero__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 180px;
  padding-bottom: 70px;
}

@media (min-width: 768px) {
  .c-hero__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 100px;
    padding-bottom: 0;
  }
}

.c-hero__content {
  position: relative;
  margin-left: auto;
  padding-right: 60px;
  color: white;
  text-align: right;
}

@media (min-width: 768px) {
  .c-hero__content {
    width: 80%;
    padding-right: 116px;
  }
}

@media (min-width: 1024px) {
  .c-hero__content {
    width: 61%;
    padding-right: 116px;
  }
}

.c-hero__text {
  max-width: 415px;
  margin-left: auto;
  font-size: 13px;
  padding-bottom: 15px;
  /* stylelint-disable */
  /* stylelint-enable */
}

@media (min-width: 768px) {
  .c-hero__text {
    font-size: 17px;
  }
}

.c-hero__text form {
  position: relative;
  display: flex;
  margin-top: 30px;
}

.c-hero__text .gfield {
  margin: 0;
}

.c-hero__text .gform_body {
  width: 100%;
}

.c-hero__text .validation_error {
  display: none;
}

.c-hero__socials {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  transform-origin: 100% 100%;
  transform: rotate(-90deg) translateX(100%);
  color: #171b27;
  font-weight: bold;
}

.c-hero__socials p {
  margin: 0;
  padding: 5px 0;
}

.c-hero__socials p:after {
  content: '';
  display: inline-block;
  width: 60px;
  height: 2px;
  margin-left: 20px;
  margin-right: 20px;
  vertical-align: middle;
  background-color: #171b27;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .c-hero__socials p:after {
    width: 30px;
  }
}

.c-hero__socials-list {
  display: flex;
  margin: 0;
  list-style: none;
}

.c-hero__socials-list li {
  margin: 0;
}

.c-hero__socials-link {
  position: relative;
  display: block;
  padding: 5px 8px;
}

@media (min-width: 768px) {
  .c-hero__socials-link {
    padding: 3px 14px;
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .c-hero__socials-link {
    padding: 5px;
  }
}

.c-hero__socials-link .dark {
  transition: all 300ms ease-in-out;
}

.c-hero__socials-link .white {
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  transition: all 300ms ease-in-out;
  transform: translate(-50%, -50%);
}

.c-hero__socials-link svg {
  width: 20px;
  height: 20px;
  transform: rotate(90deg);
}

.c-hero__socials-link svg path {
  fill: #171b27;
  filter: none;
  transition: all 300ms ease-in-out;
}

.c-hero__socials-link:hover .white {
  opacity: 1;
}

.c-hero__socials-link:hover .dark {
  opacity: 0;
}

.c-hero__socials-link:hover svg path {
  fill: white;
  filter: none;
}

.c-hero--post {
  margin-top: 84px;
  padding-bottom: 35%;
}

body.home {
  padding-top: 0;
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
.c-main-nav__link {
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Zilla Slab", serif;
  font-size: 17px;
  font-weight: 700;
  border-bottom: 1px solid #d8dbde;
  background-color: white;
  transition: all 300ms ease-in-out;
}

@media (min-width: 1024px) {
  .c-main-nav__link {
    padding: 25px 65px;
    font-size: 24px;
  }
}

.c-main-nav__link:hover {
  color: #171b27;
  background-color: #f0f4f7;
}

.current-menu-item .c-main-nav__link {
  background-color: #f0f4f7;
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
}

.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  background: #fff;
  border-bottom: 1px solid #d8dbde;
  display: block;
  padding: 0.5em;
  text-decoration: none;
}

.current-menu-item .c-main-nav__sublink {
  border-bottom-color: #000;
}

.c-main-nav__toggle {
  display: block;
  position: relative;
  margin-top: 6px;
  user-select: none;
  z-index: 1;
}

.c-main-nav__toggle:hover > span {
  background-color: #171b27;
}

.c-main-nav__toggle-checkbox {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  -webkit-touch-callout: none;
}

.c-main-nav__toggle-checkbox:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #171b27;
}

.c-main-nav__toggle-checkbox:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

.c-main-nav__toggle-checkbox:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

.c-main-nav__toggle-checkbox:checked ~ .c-main-nav {
  transform: translate(-100%, 0);
}

.c-main-nav__toggle > span {
  display: block;
  width: 30px;
  height: 4px;
  margin-bottom: 6px;
  position: relative;
  background: white;
  z-index: 1;
  transform-origin: 4px 0;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

.c-main-nav__toggle > span:first-child {
  transform-origin: 0% 0%;
}

.c-main-nav__toggle > span:nth-last-child(2) {
  transform-origin: -15% 60%;
}

.c-main-nav {
  position: absolute;
  top: 60px;
  left: 60px;
  width: auto;
  max-height: calc(100vh - 100px);
  overflow: auto;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(0, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), top 0.3s cubic-bezier(0.77, 0.2, 0.05, 1), max-height 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
  border-left: 2px solid #f0f4f7;
}

@media (min-width: 1200px) {
  .c-main-nav {
    left: calc(50vw - 550px);
  }
}

.scrolled .c-main-nav, body:not(.home) .c-header .c-main-nav, .single .c-header .c-main-nav {
  top: 40px;
  max-height: calc(100vh - 80px);
}

@media (max-width: 767.98px) {
  .scrolled .c-main-nav, body:not(.home) .c-header .c-main-nav, .single .c-header .c-main-nav {
    height: 100vh;
    max-height: calc(100vh - 40px);
    width: 100vw;
    background-color: white;
  }
}

.c-main-nav__item {
  margin: 0;
  padding: 0;
  padding-right: 10px;
}

@media (min-width: 1024px) {
  .c-main-nav__item {
    padding-right: 0;
  }
}

.c-menu--title {
  pointer-events: none;
}

.c-main-nav__link-text {
  position: relative;
  display: inline-block;
}

.c-main-nav__link-text:after {
  content: '';
  position: absolute;
  left: 0;
  top: 100%;
  width: 0;
  height: 1px;
  background-color: currentColor;
  transition: all 300ms ease-in-out;
}

.current-menu-item .c-main-nav__link .c-main-nav__link-text:after,
.c-main-nav__link:hover .c-main-nav__link-text:after {
  width: 100%;
}

.c-work.c-newsletter {
  padding-top: 65px;
  padding-bottom: 40px;
}

@media (max-width: 767.98px) {
  .c-work.c-newsletter h2 {
    font-size: 24px;
  }
  .c-work.c-newsletter p {
    font-size: 13px;
  }
}

.c-newsletter__content {
  /* stylelint-disable */
  /* stylelint-enable */
}

@media (max-width: 767.98px) {
  .c-newsletter__content p,
  .c-newsletter__content h2 {
    color: #171b27;
  }
}

.c-newsletter__content form {
  position: relative;
  display: flex;
  max-width: 575px;
  margin: 10px auto;
  margin-top: 40px;
  padding-left: 75px;
  background: url("../assets/images/email-icon.png") no-repeat left center;
}

@media (min-width: 768px) {
  .c-newsletter__content form {
    margin: 30px auto;
    padding: 0 75px;
    background-position: left center;
  }
}

.c-newsletter__content .gfield {
  margin: 0;
}

.c-newsletter__content .gform_body {
  width: 100%;
}

.c-newsletter__content .validation_error {
  display: none;
}

.c-donation__content {
  /* stylelint-disable */
  /* stylelint-enable */
}

.c-donation__content p,
.c-donation__content h2 {
  text-align: center !important;
  color: white !important;
}

.c-donation__content form {
  display: block;
  padding: 0;
  background: none;
}

.c-donation__content .gfield_radio {
  display: flex;
  margin: 0 -10px;
}

.c-donation__content .gfield_radio li {
  flex: 1;
  position: relative;
  overflow: hidden;
  margin: 0 10px;
  padding: 0;
}

.c-donation__content .gfield_radio li input {
  position: absolute;
  left: -999px;
  opacity: 0;
}

.c-donation__content .gfield_radio li label {
  display: block;
  padding: 9px 5px;
  font-size: 13px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .c-donation__content .gfield_radio li label {
    padding: 13px 30px;
    font-size: 18px;
  }
}

.c-donation__content .gfield_radio li input:checked + label {
  background-color: black;
  color: white;
}

.c-donation__content .gform_wrapper {
  margin-top: 20px;
}

.c-donation__content .gform_footer {
  text-align: center;
}

.c-donation__content .gform_button {
  width: 210px;
  margin-top: 15px;
}

.c-donation__content .gfield_total {
  display: none;
}

.c-donation__content .ginput_amount {
  display: block;
  width: calc(50% - 10px);
  margin-top: 10px;
  margin-left: auto;
  background-color: transparent;
  border: 2px solid white;
  color: white;
}

@media (min-width: 768px) {
  .c-donation__content .ginput_amount {
    width: calc(25% - 15px);
  }
}

.c-donation__content .ginput_amount::placeholder {
  color: currentColor;
  font-size: 12px;
  font-style: italic;
}

.c-donation__content .ginput_amount::-webkit-input-placeholder {
  color: currentColor;
  font-size: 12px;
  font-style: italic;
}

.c-donation__content .ginput_amount::-moz-placeholder {
  color: currentColor;
  font-size: 12px;
  font-style: italic;
}

.c-page__hero {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

.c-page__hero h1 {
  font-weight: bold;
}

@media (min-width: 1200px) {
  .c-page__hero h1 {
    font-size: 90px;
  }
}

.c-page__hero-thumbnail {
  color: white;
  text-align: center;
}

.c-page__subtitle {
  max-width: 550px;
  margin: 0 auto;
}

.c-section {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width: 1024px) {
  .c-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

/* donate */
.c-page__donate--column {
  max-width: 450px;
}

@media (min-width: 1024px) {
  .c-page__donate--column h3, .c-page__donate--column .c-post__tagline {
    margin-bottom: 25px;
  }
}

.c-page__donate .c-work .o-wrapper,
.c-page__donate .c-section .o-wrapper {
  max-width: 1000px;
}

.c-page__donate .c-section + .c-section {
  padding-top: 0;
}

.c-page__donate .c-donation {
  padding-top: 0;
  background: none !important;
}

.c-page__donate .c-donation,
.c-page__donate .c-donation .c-donation__content p,
.c-page__donate .c-donation .c-donation__content h2 {
  color: #171b27 !important;
}

.c-page__custom-title {
  padding-right: 20px;
}

@media (min-width: 1200px) {
  .c-page__custom-title {
    min-width: 390px;
  }
}

.c-page__custom-title span {
  border-bottom: 3px solid currentColor;
}

@media (min-width: 1200px) {
  .c-page__content-inner {
    padding-right: 120px;
  }
}

.c-page__tabs {
  padding-top: 35px;
  background-color: #171b27;
}

.c-page__tabs,
.c-page__tabs a {
  color: white;
}

.c-page__tabs-nav {
  padding-bottom: 35px;
  border-bottom: 1px solid #f7f7f7;
}

.c-page__tabs-nav-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .c-page__tabs-nav-content {
    justify-content: space-between;
  }
}

.c-page__tabs-nav-content:after {
  display: none !important;
}

.c-page__tabs-nav-link {
  display: flex;
  align-items: center;
  padding: 15px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Zilla Slab", serif;
  font-weight: bold;
  letter-spacing: 2px;
}

@media (min-width: 768px) {
  .c-page__tabs-nav-link {
    padding: 0;
  }
}

.c-page__tabs-nav-link:hover {
  color: #00cdf2;
}

.c-page__tabs-nav-link img {
  height: 45px;
}

.c-page__tabs-nav-link .img-active {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: all 300ms ease-in-out;
}

.c-page__tabs-nav-link.active, .c-page__tabs-nav-link:hover {
  color: #00cdf2;
}

.c-page__tabs-nav-link.active .img-active, .c-page__tabs-nav-link:hover .img-active {
  opacity: 1;
}

.c-page__tabs-nav-icons {
  position: relative;
  display: block;
  margin-right: 20px;
}

.c-page__tabs-arrow {
  display: none;
}

@media (min-width: 768px) {
  .c-page__tabs-arrow {
    display: block;
    width: 20px;
    height: 10px;
  }
}

.c-page__tabs-arrow img {
  display: block;
}

.c-page__tabs-tab {
  display: none;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 35px;
}

@media (min-width: 768px) {
  .c-page__tabs-tab {
    flex-wrap: nowrap;
  }
}

@media (min-width: 1024px) {
  .c-page__tabs-tab {
    padding-top: 100px;
  }
}

.c-page__tabs-tab.active {
  display: flex;
}

.c-page__tabs-tab--left {
  margin-bottom: 20px;
  text-align: center;
}

.c-page__tabs-tab--left img {
  margin-top: 15px;
}

@media (min-width: 1024px) {
  .c-page__tabs-tab--left img {
    margin-top: 35px;
  }
}

.c-page__donate .c-donation__js h4 {
  font-family: "Raleway", sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif;
}

@media (min-width: 1024px) {
  .c-page__donate .c-donation__js h4 {
    margin: 40px 0;
  }
}

.c-page__donate .c-donation__js .gform_footer {
  display: none;
}

.c-page__donate .c-donation__js .gfield_checkbox {
  list-style: none;
}

.c-page__donate .c-donation__js .gfield_checkbox li {
  margin-top: 35px;
  margin-bottom: 0;
  text-align: center;
}

.c-page__donate .c-donation__js .gfield_checkbox input {
  position: absolute;
  left: -999px;
  opacity: 0;
  visibility: hidden;
}

.c-page__donate .c-donation__js .gfield_checkbox input:checked + label:after {
  display: block;
}

.c-page__donate .c-donation__js .gfield_checkbox label {
  position: relative;
  padding-left: 40px;
}

.c-page__donate .c-donation__js .gfield_checkbox label:before, .c-page__donate .c-donation__js .gfield_checkbox label:after {
  content: '';
  position: absolute;
  display: block;
}

.c-page__donate .c-donation__js .gfield_checkbox label:before {
  left: 0;
  top: 50%;
  width: 18px;
  height: 18px;
  margin-top: -9px;
  border: 3px solid #171b27;
}

.c-page__donate .c-donation__js .gfield_checkbox label:after {
  display: none;
  top: calc(50% - 3px);
  left: 4px;
  width: 21px;
  height: 17px;
  background: url("../assets/images/icon-tick.png") no-repeat center center;
  background-size: contain;
  transform: translateY(-50%);
}

.c-page__donate .c-donation__js .ginput_amount {
  border-color: #171b27;
  color: #171b27;
}

.c-page__donate .c-donation__one-time.hide {
  display: none;
}

.c-page__donate .c-donation__one-time .gform_footer {
  display: block;
}

.c-page__donate .c-donation__one-time .gform_body {
  display: none;
}

.c-page__donate-form {
  padding-bottom: 30px;
}

.c-page__donate-form.hide {
  display: none;
}

@media (min-width: 768px) {
  .c-page__donate-form {
    padding-bottom: 50px;
  }
}

@media (min-width: 1200px) {
  .c-page__donate-form {
    padding-bottom: 100px;
  }
}

.c-page__donate-form hr {
  margin-bottom: 30px;
  border: 1px solid #e3e3e5;
  background-color: none;
}

.c-page__donate-form .o-wrapper--full {
  max-width: 1200px;
}

.c-page__donate-form .gfield_required {
  display: none;
}

@media (min-width: 768px) {
  .c-page__donate-form .gform_fields {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }
}

.c-page__donate-form .gfield {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .c-page__donate-form .gfield {
    width: 100%;
    padding: 5px;
  }
}

@media (min-width: 768px) {
  .c-page__donate-form .gfield.half {
    width: 50%;
  }
}

.c-page__donate-form .gfield:nth-child(1) .gfield_label {
  display: block;
  font-weight: bold;
}

.c-page__donate-form .gform_wrapper {
  max-width: 695px;
  margin: 0 auto;
}

.c-page__donate-form input,
.c-page__donate-form select {
  border-radius: 2px;
  border: 1px solid #e3e3e5;
  background-color: #f7f7f7;
}

@media (min-width: 768px) {
  .c-page__donate-form input,
  .c-page__donate-form select {
    height: 52px;
  }
}

.c-page__donate-form .gfield_html {
  margin-bottom: 15px;
  padding: 0;
  border-bottom: 1px solid #e3e3e5;
}

@media (min-width: 768px) {
  .c-page__donate-form .gfield_html {
    margin: 0 5px;
    margin-bottom: 25px;
  }
}

.c-page__donate-form .form-details {
  position: relative;
  display: inline-block;
  padding: 14px 20px 10px;
  font-weight: bold;
  background-color: white;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: 1px solid #e3e3e5;
  border-bottom: none;
}

.c-page__donate-form .form-details:before, .c-page__donate-form .form-details:after {
  content: '';
  position: absolute;
  left: 0;
  display: block;
  width: 100%;
}

.c-page__donate-form .form-details:after {
  bottom: -1px;
  height: 2px;
  background-color: white;
}

.c-page__donate-form .form-details:before {
  top: 0;
  height: 3px;
  background-color: #00cdf2;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.c-page__donate-form .gform_footer {
  margin-top: 35px;
  text-align: center;
}

.c-page__donate-form .gform_button {
  display: inline-block;
  border: none;
}

.c-page__donate-form .gform_button:hover {
  background-color: #171b27;
  color: white;
}

.c-page__donate-form--content {
  padding: 20px;
  background-color: white;
}

@media (min-width: 1200px) {
  .c-page__donate-form--content {
    padding: 60px;
  }
}

@media (max-width: 1023.98px) {
  .c-popular-posts .c-posts-scroller__title {
    margin-bottom: 10px;
  }
}

@media (min-width: 1024px) {
  .c-popular-posts .c-popular-posts__list-col-1 {
    width: 35.5%;
  }
}

.c-popular-posts .c-popular-posts__list-col-2 {
  margin-top: 10px;
}

@media (min-width: 1024px) {
  .c-popular-posts .c-popular-posts__list-col-2 {
    width: 44.5%;
    margin-top: 0;
    padding: 0 20px;
  }
}

.c-popular-posts .c-popular-posts__list-col-3 {
  margin-top: 10px;
}

@media (min-width: 1024px) {
  .c-popular-posts .c-popular-posts__list-col-3 {
    width: 20%;
    margin-top: 0;
  }
}

.c-popular-posts .c-tease__category--popular,
.c-popular-posts .c-tease__desc {
  display: none;
}

.c-popular-posts__list-col-1 .c-tease {
  height: 100%;
}

@media (min-width: 1024px) {
  .c-popular-posts__list-col-1 .c-tease__popular {
    padding: 35px 55px;
  }
}

@media (max-width: 1023.98px) {
  .c-popular-posts__list-col-1 .c-tease__popular h4 {
    font-size: 24px;
  }
}

@media (max-width: 1023.98px) {
  .c-popular-posts__list-col-1 .c-tease__popular:before {
    display: block;
    width: 30%;
  }
}

.c-popular-posts__list-col-1 .c-tease__category--popular,
.c-popular-posts__list-col-1 .c-tease__desc {
  display: block;
}

.c-recent-posts__list-col.c-popular-posts__list-col-2 {
  display: flex;
  flex-wrap: wrap;
}

.c-recent-posts__list-col.c-popular-posts__list-col-2 .c-tease {
  width: 50%;
}

.c-recent-posts__list-col.c-popular-posts__list-col-2 .c-tease:first-child {
  width: 100%;
  margin-bottom: 10px;
}

@media (min-width: 1024px) {
  .c-recent-posts__list-col.c-popular-posts__list-col-2 .c-tease:first-child {
    margin-bottom: 20px;
  }
}

@media (max-width: 1023.98px) {
  .c-recent-posts__list-col.c-popular-posts__list-col-2 .c-tease:first-child .c-tease__popular {
    padding-top: 40px;
  }
}

.c-recent-posts__list-col.c-popular-posts__list-col-2 .c-tease:nth-child(2) {
  padding-right: 5px;
}

@media (min-width: 1024px) {
  .c-recent-posts__list-col.c-popular-posts__list-col-2 .c-tease:nth-child(2) {
    padding-right: 10px;
  }
}

.c-recent-posts__list-col.c-popular-posts__list-col-2 .c-tease:nth-child(3) {
  padding-left: 5px;
}

@media (min-width: 1024px) {
  .c-recent-posts__list-col.c-popular-posts__list-col-2 .c-tease:nth-child(3) {
    padding-left: 10px;
  }
}

.c-recent-posts__list-col.c-popular-posts__list-col-2 .c-tease:nth-child(2) h4,
.c-recent-posts__list-col.c-popular-posts__list-col-2 .c-tease:nth-child(3) h4 {
  font-size: 16px;
}

@media (min-width: 1024px) {
  .c-recent-posts__list-col.c-popular-posts__list-col-2 .c-tease:nth-child(2) h4,
  .c-recent-posts__list-col.c-popular-posts__list-col-2 .c-tease:nth-child(3) h4 {
    font-size: 21px;
  }
}

.c-recent-posts__list-col.c-popular-posts__list-col-2 .c-tease:nth-child(2) .c-tease__popular,
.c-recent-posts__list-col.c-popular-posts__list-col-2 .c-tease:nth-child(3) .c-tease__popular {
  padding-bottom: 45px;
}

.c-recent-posts__list-col.c-popular-posts__list-col-2 .c-tease:nth-child(2) .c-tease__timestamp .divider,
.c-recent-posts__list-col.c-popular-posts__list-col-2 .c-tease:nth-child(3) .c-tease__timestamp .divider {
  display: none;
}

.c-recent-posts__list-col.c-popular-posts__list-col-2 .c-tease:nth-child(2) .c-tease__timestamp .views,
.c-recent-posts__list-col.c-popular-posts__list-col-2 .c-tease:nth-child(3) .c-tease__timestamp .views {
  display: block;
}

@media (max-width: 1023.98px) {
  .c-recent-posts__list-col.c-popular-posts__list-col-2 .c-tease:nth-child(2) .c-tease__author,
  .c-recent-posts__list-col.c-popular-posts__list-col-2 .c-tease:nth-child(3) .c-tease__author {
    font-size: 10px;
    letter-spacing: 1px;
  }
}

@media (max-width: 1023.98px) {
  .c-recent-posts__list-col.c-popular-posts__list-col-3 {
    display: flex;
    align-items: stretch;
  }
  .c-recent-posts__list-col.c-popular-posts__list-col-3 .c-tease:nth-child(1) {
    padding-right: 5px;
  }
  .c-recent-posts__list-col.c-popular-posts__list-col-3 .c-tease:nth-child(2) {
    padding-left: 5px;
  }
  .c-recent-posts__list-col.c-popular-posts__list-col-3 h4 {
    font-size: 16px;
  }
  .c-recent-posts__list-col.c-popular-posts__list-col-3 .c-tease__author {
    font-size: 10px;
    letter-spacing: 1px;
  }
}

.c-recent-posts__list-col.c-popular-posts__list-col-3 .c-tease__timestamp .divider {
  display: none;
}

.c-recent-posts__list-col.c-popular-posts__list-col-3 .c-tease__timestamp .views {
  display: block;
}

@media (min-width: 1024px) {
  .c-recent-posts__list-col.c-popular-posts__list-col-3 .c-tease:first-child {
    margin-bottom: 20px;
  }
}

@media (min-width: 1024px) {
  .c-recent-posts__list-col.c-popular-posts__list-col-3 .c-tease h4 {
    font-size: 21px;
  }
}

.c-recent-posts__list-col.c-popular-posts__list-col-3 .c-tease__popular {
  padding-bottom: 45px;
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post {
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 17px;
  overflow-x: hidden;
}

@media (min-width: 768px) {
  .c-post {
    padding-bottom: 50px;
  }
}

@media (min-width: 1200px) {
  .c-post {
    padding-bottom: 100px;
  }
}

.c-post a {
  word-break: break-all;
}

.c-post__title {
  font-weight: bold;
}

@media (min-width: 1200px) {
  .c-post__title {
    font-size: 56px;
  }
}

@media (min-width: 1600px) {
  .c-post__title {
    font-size: 62px;
  }
}

.c-post__tagline {
  margin-top: 30px;
  font-weight: bold;
}

@media (min-width: 1024px) {
  .c-post__tagline {
    font-size: 26px;
  }
}

.c-post__content {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: white;
}

@media (min-width: 1024px) {
  .c-post__content.o-wrapper--supernarrow {
    max-width: 900px;
    padding-left: 35px;
    padding-right: 35px;
  }
}

.c-post__content p {
  font-family: Georgia;
  font-size: 19.2px;
  color: #191919;
}

@media (min-width: 1024px) {
  .c-post__content p {
    font-size: 17px;
  }
}

.c-post__meta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

@media (min-width: 1200px) {
  .c-post__meta {
    margin-bottom: 45px;
  }
}

.c-post__meta .c-tease__category {
  margin-bottom: 0;
}

.c-post__author {
  color: #00cdf2;
}

.c-post__timestamp.c-tease__timestamp {
  margin-top: 45px;
  margin-bottom: 30px;
  background: url("../assets/images/clock-icon-dark.png") no-repeat left center;
}

.c-post__thumb {
  width: 100%;
  height: auto;
}

.c-post__nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.c-post__nav-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e3e3e5;
}

@media (min-width: 1024px) {
  .c-post__nav-links {
    margin-top: 60px;
  }
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

figcaption {
  font-style: italic;
}

.a2a_kit a {
  width: 44px;
  height: 35px;
  margin-left: -1px;
  padding: 0 !important;
  line-height: 35px;
  text-align: center;
  border: 1px solid #e3e3e5 !important;
}

.a2a_kit a:hover {
  background-color: #171b27;
}

.a2a_kit a:hover span {
  opacity: 1 !important;
}

.a2a_kit a:hover path {
  fill: white;
}

.video-shortcode {
  max-width: 650px;
  margin: 40px auto;
}

.video-shortcode .c-tease__thumb,
.video-shortcode .c-tease__thumb--video {
  height: auto;
}

.video-shortcode .c-tease__thumb--video .c-embed--video {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  z-index: 0;
}

.video-shortcode .c-tease__thumb--video .c-embed--video iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.video-shortcode .c-tease__thumb--video-overlay {
  height: 100%;
  z-index: 1;
}

.video-shortcode .c-tease__thumb--video-play {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -25px;
  transform: translateX(-50%);
}

blockquote {
  position: relative;
  max-width: 640px;
  margin: 25px 0;
  padding: 20px 0 20px 45px;
}

@media (min-width: 1200px) {
  blockquote {
    padding-left: 130px;
  }
}

blockquote:before, blockquote:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 100%;
}

@media (min-width: 1200px) {
  blockquote:before, blockquote:after {
    left: 90px;
  }
}

blockquote:before {
  width: 8px;
  background-color: #00cdf2;
}

blockquote:after {
  width: 25px;
  border-top: 8px solid #00cdf2;
  border-bottom: 8px solid #00cdf2;
}

blockquote * {
  font-family: "Raleway", sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif;
}

blockquote *:not(cite) {
  font-weight: bold;
  font-size: 18px;
}

blockquote cite {
  font-size: 13px;
  color: #00cdf2;
  text-transform: uppercase;
  font-style: normal;
}

.c-posts__connected {
  padding-bottom: 30px;
}

@media (min-width: 1024px) {
  .c-posts__connected {
    padding-bottom: 100px;
  }
}

.c-posts__connected h3, .c-posts__connected .c-post__tagline {
  text-transform: uppercase;
}

@media (min-width: 1200px) {
  .c-posts__connected h3, .c-posts__connected .c-post__tagline {
    font-size: 30px;
  }
}

.c-posts__connected-items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
}

.c-posts__connected-item {
  width: 100%;
  padding: 10px 20px;
}

@media (min-width: 768px) {
  .c-posts__connected-item {
    width: 50%;
  }
}

.c-posts__connected-item h5 {
  margin: 0;
  line-height: 1.2;
}

.c-posts__connected-item .c-tease__author {
  margin-top: 5px;
}

.c-posts__connected-item .img {
  flex: 0 0 150px;
  width: 150px;
  height: auto;
  background-size: cover;
}

.c-posts__connected-item__content {
  display: flex;
  height: 100%;
  padding: 20px;
  text-decoration: none;
  background-color: white;
}

.c-posts__connected-item__content:hover {
  color: #171b27;
}

.c-posts__connected-item__content:hover h5 {
  text-decoration: underline;
}

.c-posts__connected-item__text {
  padding-left: 20px;
}

.c-posts-scroller {
  padding: 15px 0;
}

@media (min-width: 768px) {
  .c-posts-scroller {
    padding: 50px 0;
  }
}

.c-posts-scroller__title {
  margin-bottom: 0;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .c-posts-scroller__title {
    margin-bottom: 35px;
  }
}

.c-posts-scroller__content {
  padding: 0 15px;
}

@media (min-width: 768px) {
  .c-posts-scroller__content {
    padding: 0;
    white-space: nowrap;
    overflow: auto;
  }
}

@media (min-width: 1200px) {
  .c-posts-scroller__content .c-tease:first-child {
    margin-left: calc((100vw - 1185px) / 2);
  }
}

@media (min-width: 1200px) {
  .c-posts-scroller__content .c-tease:last-child {
    margin-right: calc((100vw - 1185px) / 2);
  }
}

.c-posts-scroller__content .c-tease:hover {
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
}

@media (max-width: 767.98px) {
  .c-posts-scroller__content .c-tease {
    display: flex;
    align-items: stretch;
  }
}

.c-posts-scroller__content .c-tease .c-tease__desc,
.c-posts-scroller__content .c-tease .c-tease__author,
.c-posts-scroller__content .c-tease .c-btn,
.c-posts-scroller__content .c-tease .gform_button,
.c-posts-scroller__content .c-tease .c-donation__content .gfield_radio li label,
.c-donation__content .gfield_radio li .c-posts-scroller__content .c-tease label,
.c-posts-scroller__content .c-tease .c-post__nav a,
.c-post__nav .c-posts-scroller__content .c-tease a,
.c-posts-scroller__content .c-tease .c-work a:not(.c-work__social),
.c-work .c-posts-scroller__content .c-tease a:not(.c-work__social) {
  display: none;
}

@media (min-width: 768px) {
  .c-posts-scroller__content .c-tease .c-tease__desc,
  .c-posts-scroller__content .c-tease .c-tease__author,
  .c-posts-scroller__content .c-tease .c-btn,
  .c-posts-scroller__content .c-tease .gform_button,
  .c-posts-scroller__content .c-tease .c-donation__content .gfield_radio li label,
  .c-donation__content .gfield_radio li .c-posts-scroller__content .c-tease label,
  .c-posts-scroller__content .c-tease .c-post__nav a,
  .c-post__nav .c-posts-scroller__content .c-tease a,
  .c-posts-scroller__content .c-tease .c-work a:not(.c-work__social),
  .c-work .c-posts-scroller__content .c-tease a:not(.c-work__social) {
    display: block;
  }
}

@media (max-width: 767.98px) {
  .c-posts-scroller__content .c-tease__content {
    flex: 1;
    padding: 10px 20px;
  }
  .c-posts-scroller__content .c-tease__thumb--video,
  .c-posts-scroller__content .c-tease__thumb {
    flex: 0 0 150px;
    width: 100px;
    height: auto;
    order: 2;
  }
  .c-posts-scroller__content .c-tease__thumb--video-overlay {
    height: 100%;
  }
  .c-posts-scroller__content h4 {
    margin-bottom: 10px;
    font-size: 14px;
  }
}

@media (max-width: 479.98px) {
  .c-posts-scroller__content .c-tease__thumb--video,
  .c-posts-scroller__content .c-tease__thumb {
    flex: 0 0 100px;
  }
}

.c-posts-scroller--theologie .c-btn, .c-posts-scroller--theologie .gform_button, .c-posts-scroller--theologie .page-contact .c-page__content .gform_button, .page-contact .c-page__content .c-posts-scroller--theologie .gform_button, .c-posts-scroller--theologie .c-donation__content .gfield_radio li label, .c-donation__content .gfield_radio li .c-posts-scroller--theologie label, .c-posts-scroller--theologie .c-post__nav a, .c-post__nav .c-posts-scroller--theologie a, .c-posts-scroller--theologie .c-work a:not(.c-work__social), .c-work .c-posts-scroller--theologie a:not(.c-work__social) {
  background-color: #00d8df;
}

.c-posts-scroller--theologie .c-btn:hover, .c-posts-scroller--theologie .gform_button:hover, .c-posts-scroller--theologie .c-donation__content .gfield_radio li label:hover, .c-donation__content .gfield_radio li .c-posts-scroller--theologie label:hover, .c-posts-scroller--theologie .c-post__nav a:hover, .c-post__nav .c-posts-scroller--theologie a:hover, .c-posts-scroller--theologie .c-work a:hover:not(.c-work__social), .c-work .c-posts-scroller--theologie a:hover:not(.c-work__social), .c-posts-scroller--theologie .c-btn:focus, .c-posts-scroller--theologie .gform_button:focus, .c-posts-scroller--theologie .c-donation__content .gfield_radio li label:focus, .c-donation__content .gfield_radio li .c-posts-scroller--theologie label:focus, .c-posts-scroller--theologie .c-post__nav a:focus, .c-post__nav .c-posts-scroller--theologie a:focus, .c-posts-scroller--theologie .c-work a:focus:not(.c-work__social), .c-work .c-posts-scroller--theologie a:focus:not(.c-work__social) {
  background-color: #171b27;
}

.c-posts-scroller--theologie .c-tease__thumb--video-play:hover,
.c-posts-scroller--theologie .c-posts-scroller__title a:hover,
.c-posts-scroller--theologie h4 a:hover {
  color: #00d8df;
}

.c-posts-scroller--theologie .simplebar-scrollbar:before {
  background-color: #00d8df;
}

.c-posts-scroller--theologie .c-tease__thumb--video-play:hover p:after {
  border-color: transparent transparent transparent #00d8df;
}

.c-posts-scroller--theologie .c-tease__thumb--video-play circle {
  stroke: #00d8df;
}

.c-posts-scroller--geschiedenis .c-btn, .c-posts-scroller--geschiedenis .gform_button, .c-posts-scroller--geschiedenis .page-contact .c-page__content .gform_button, .page-contact .c-page__content .c-posts-scroller--geschiedenis .gform_button, .c-posts-scroller--geschiedenis .c-donation__content .gfield_radio li label, .c-donation__content .gfield_radio li .c-posts-scroller--geschiedenis label, .c-posts-scroller--geschiedenis .c-post__nav a, .c-post__nav .c-posts-scroller--geschiedenis a, .c-posts-scroller--geschiedenis .c-work a:not(.c-work__social), .c-work .c-posts-scroller--geschiedenis a:not(.c-work__social) {
  background-color: #171b27;
}

.c-posts-scroller--geschiedenis .c-btn:hover, .c-posts-scroller--geschiedenis .gform_button:hover, .c-posts-scroller--geschiedenis .c-donation__content .gfield_radio li label:hover, .c-donation__content .gfield_radio li .c-posts-scroller--geschiedenis label:hover, .c-posts-scroller--geschiedenis .c-post__nav a:hover, .c-post__nav .c-posts-scroller--geschiedenis a:hover, .c-posts-scroller--geschiedenis .c-work a:hover:not(.c-work__social), .c-work .c-posts-scroller--geschiedenis a:hover:not(.c-work__social), .c-posts-scroller--geschiedenis .c-btn:focus, .c-posts-scroller--geschiedenis .gform_button:focus, .c-posts-scroller--geschiedenis .c-donation__content .gfield_radio li label:focus, .c-donation__content .gfield_radio li .c-posts-scroller--geschiedenis label:focus, .c-posts-scroller--geschiedenis .c-post__nav a:focus, .c-post__nav .c-posts-scroller--geschiedenis a:focus, .c-posts-scroller--geschiedenis .c-work a:focus:not(.c-work__social), .c-work .c-posts-scroller--geschiedenis a:focus:not(.c-work__social) {
  background-color: #00cdf2;
}

.c-posts-scroller--geschiedenis .c-tease__thumb--video-play:hover,
.c-posts-scroller--geschiedenis .c-posts-scroller__title a:hover {
  color: #171b27;
}

.c-posts-scroller--geschiedenis .simplebar-scrollbar:before {
  background-color: #171b27;
}

.c-posts-scroller--geschiedenis .c-tease__thumb--video-play:hover p:after {
  border-color: transparent transparent transparent white;
}

.c-posts-scroller--geschiedenis .c-tease__thumb--video-play circle {
  stroke: white;
}

.c-posts-scroller__mobile {
  border-top: 1px solid #cfdde6;
  text-align: right;
}

@media (min-width: 768px) {
  .c-posts-scroller__mobile {
    display: none;
  }
}

.c-recent-posts {
  padding: 20px 0;
}

@media (min-width: 1024px) {
  .c-recent-posts {
    padding: 40px 0;
  }
}

@media (min-width: 1024px) {
  .c-recent-posts__list {
    display: flex;
  }
}

@media (min-width: 1024px) {
  .c-recent-posts__list-col {
    width: 50%;
  }
}

@media (min-width: 1024px) {
  .c-recent-posts__list-col-left {
    padding-right: 20px;
  }
}

@media (min-width: 1024px) {
  .c-recent-posts__list-col-right {
    padding-left: 20px;
  }
}

.c-recent-posts__list-col .c-tease {
  width: 100%;
  margin: 0;
}

.c-recent-posts__list-col-right {
  margin-top: 20px;
}

@media (min-width: 1024px) {
  .c-recent-posts__list-col-right {
    margin-top: 0;
  }
}

.c-recent-posts__list-col-right .c-tease + .c-tease {
  margin-top: 20px;
}

@media (min-width: 1024px) {
  .c-recent-posts__list-col-right .c-tease + .c-tease {
    margin-top: 40px;
  }
}

.c-recent-posts__list-col-right .c-tease__author,
.c-recent-posts__list-col-right .c-tease__desc {
  display: none;
}

.c-recent-posts__list-col-left .c-tease {
  height: 100%;
}

.c-recent-posts__list-col-left .c-tease h4 {
  font-size: 24px;
}

@media (min-width: 1024px) {
  .c-recent-posts__list-col-left .c-tease h4 {
    font-size: 40px;
  }
}

@media (min-width: 1024px) {
  .c-recent-posts__list-col-left .c-tease__timestamp {
    margin-top: 40px;
  }
}

.c-tease {
  position: relative;
  transition: all 300ms ease-in-out;
  overflow: hidden;
  background-color: white;
}

.c-tease .c-btn, .c-tease .gform_button, .c-tease .page-contact .c-page__content .gform_button, .page-contact .c-page__content .c-tease .gform_button, .c-tease .c-donation__content .gfield_radio li label, .c-donation__content .gfield_radio li .c-tease label, .c-tease .c-post__nav a, .c-post__nav .c-tease a, .c-tease .c-work a:not(.c-work__social), .c-work .c-tease a:not(.c-work__social) {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.c-tease:hover .c-tease__bg {
  transform: scale(1.1);
}

.c-tease--post {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  margin: 10px 0;
  white-space: normal;
  vertical-align: top;
}

@media (min-width: 768px) {
  .c-tease--post {
    width: 350px;
    margin: 0 20px 30px 20px;
  }
}

.c-tease__wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.c-tease__thumb {
  position: relative;
  display: block;
  height: 175px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
}

.c-tease__content {
  position: relative;
  padding: 20px;
  z-index: 1;
}

@media (min-width: 768px) {
  .c-tease__content {
    padding: 30px;
    padding-bottom: 25px;
  }
}

.c-tease__author {
  margin-top: 50px;
  text-transform: uppercase;
  font-family: "Zilla Slab", serif;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 2px;
}

.c-tease__category {
  min-height: 35px;
  padding-left: 40px;
  padding-top: 13px;
  font-family: "Zilla Slab", serif;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-repeat: no-repeat;
  background-position: left center;
}

@media (max-width: 767.98px) {
  .c-tease__category {
    margin-bottom: 10px;
  }
}

.c-tease__category.c-tease__category--recent {
  padding-top: 8px;
}

@media (min-width: 768px) {
  .c-tease__category.c-tease__category--recent {
    padding-top: 15px;
  }
}

@media (min-width: 768px) {
  .c-tease__category {
    padding-top: 15px;
  }
}

.c-tease__category--politiek {
  background-image: url("../assets/images/politics-icon-color.png");
}

.c-tease__category--theologie {
  background-image: url("../assets/images/theologie-icon-color.png");
}

.c-tease__category--identiteit {
  background-image: url("../assets/images/identiteit-icon-color.png");
}

.c-tease__category--geschiedenis {
  background-image: url("../assets/images/geschiedenis-icon-color.png");
}

.c-tease__category--recent {
  background-image: url("../assets/images/recent-icon-white.png");
}

.c-tease__thumb--video {
  position: relative;
  height: 175px;
  background-color: black;
}

.c-tease__thumb--video .c-embed {
  display: none;
}

@media (min-width: 768px) {
  .c-tease__thumb--video .c-embed {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.c-tease__thumb--video .c-embed > * {
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .c-tease__thumb--video-overlay {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
    padding: 10px;
  }
  .c-tease__thumb--video-overlay.hide {
    display: none;
  }
}

.c-tease__thumb--video-play {
  display: none;
}

@media (min-width: 768px) {
  .c-tease__thumb--video-play {
    position: relative;
    display: flex;
    align-items: center;
    height: 54px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    color: white;
  }
  .c-tease__thumb--video-play p {
    margin: 0;
  }
  .c-tease__thumb--video-play p:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 10px;
    border-style: solid;
    border-width: 5px 0 5px 6px;
    border-color: transparent transparent transparent white;
    transition: all 300ms ease-in-out;
  }
  .c-tease__thumb--video-play:hover p:after {
    border-color: transparent transparent transparent #00cdf2;
  }
  .c-tease__thumb--video-play svg {
    position: absolute;
    left: 100%;
    top: 50%;
    margin-top: -28px;
    margin-left: -31px;
  }
  .c-tease__thumb--video-play circle {
    stroke: #00cdf2;
    transform-origin: 50% 50%;
    transform: rotate(-149deg);
  }
}

.c-tease__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  background-size: cover;
  z-index: 0;
  transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
}

.c-tease__recent {
  position: relative;
  height: 100%;
}

@media (min-width: 768px) {
  .c-tease__recent {
    padding: 30px 60px 45px;
  }
}

.c-tease__recent:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
}

.c-tease__recent,
.c-tease__recent h4,
.c-tease__recent h4 a {
  color: white;
}

.c-tease__recent h4 a:hover {
  color: #00cdf2;
}

.c-tease__recent > * {
  position: relative;
  z-index: 1;
}

.c-tease__recent .c-tease__author {
  margin-top: 0;
}

.c-tease__recent h4 {
  font-size: 18px;
}

@media (min-width: 768px) {
  .c-tease__recent h4 {
    font-size: 30px;
  }
}

.c-tease__recent .c-tease__category {
  font-size: 13px;
}

.c-tease__recent .c-tease__author {
  font-size: 11px;
  color: #00cdf2;
}

.c-tease__timestamp {
  min-height: 16px;
  margin-top: 20px;
  padding-left: 30px;
  padding-right: 85px;
  text-transform: uppercase;
  font-family: "Zilla Slab", serif;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 2px;
  background: url("../assets/images/clock-icon.png") no-repeat left center;
}

@media (min-width: 768px) {
  .c-tease__timestamp {
    padding-right: 0;
  }
}

.c-tease__featured {
  background-color: #171b27;
}

@media (min-width: 768px) {
  .c-tease__featured {
    width: 50%;
    padding: 70px 85px 55px;
  }
}

.c-tease__featured,
.c-tease__featured h4,
.c-tease__featured h4 a {
  color: white;
}

.c-tease__featured h4 {
  font-size: 24px;
}

@media (min-width: 768px) {
  .c-tease__featured h4 {
    font-size: 40px;
  }
}

.c-tease__featured h4 a:hover {
  color: #00cdf2;
}

.c-tease__featured .c-tease__author {
  margin-top: 10px;
  color: #00cdf2;
}

.c-tease__featured .c-tease__desc {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .c-tease__featured .c-tease__desc {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}

.c-tease__featured-thumb {
  position: relative;
  height: 250px;
  max-height: 55vh;
  overflow: hidden;
}

@media (min-width: 768px) {
  .c-tease__featured-thumb {
    width: 50%;
    height: auto;
    max-height: 100vh;
  }
}

.c-tease__category--featured {
  padding-left: 0;
  background: none;
}

.c-tease__category--featured:before {
  content: '';
  display: inline-block;
  width: 30px;
  height: 10px;
  margin-right: 10px;
  margin-top: -2px;
  background-color: #00cdf2;
  vertical-align: middle;
}

.c-thumb__decorator, .c-tease__recent:before, .c-tease__featured-thumb:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 55%;
  height: 10px;
  background-color: #00cdf2;
  z-index: 1;
}

@media (min-width: 768px) {
  .c-thumb__decorator, .c-tease__recent:before, .c-tease__featured-thumb:after {
    width: 30%;
  }
}

.c-tease__popular {
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .c-tease__popular {
    padding: 40px 25px 25px;
  }
}

.c-tease__popular:before {
  display: none;
}

.c-tease__popular .c-tease__timestamp {
  padding-right: 0;
}

.c-tease__popular .c-tease__timestamp .divider {
  margin: 0 5px;
}

.c-tease__popular .c-tease__timestamp .views {
  color: #00cdf2;
}

.c-tease__category--popular {
  background-image: url("../assets/images/popular-icon-white.png");
}

.c-why {
  padding-top: 75px;
  padding-bottom: 85px;
}

.c-why__content,
.c-why__title {
  text-align: center;
}

.c-why__content {
  margin-bottom: 40px;
}

.c-work {
  padding: 70px 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: white;
}

@media (min-width: 768px) {
  .c-work {
    padding: 100px 0;
  }
}

.c-work h2 {
  font-size: 36px;
}

@media (min-width: 768px) {
  .c-work h2 {
    font-size: 48px;
  }
}

.c-work p {
  max-width: 1060px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
}

.c-work a:not(.c-work__social) {
  color: white;
  font-size: 15px;
  text-decoration: none;
}

@media (min-width: 768px) {
  .c-work a:not(.c-work__social) {
    margin-top: 60px;
  }
}

.c-work__socials {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  text-align: center;
}

@media (min-width: 768px) {
  .c-work__socials {
    margin-top: 0;
  }
}

.c-work__social {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  margin: 10px;
  vertical-align: middle;
  background-color: #171b27;
  text-decoration: none;
  transition: all 300ms ease-in-out;
}

.c-work__social:last-child {
  margin-right: 0;
}

.c-work__social:first-child {
  margin-left: 0;
}

.c-work__social svg path {
  fill: white;
  filter: none;
  transition: all 300ms ease-in-out;
}

.c-work__social .white {
  transition: all 300ms ease-in-out;
}

.c-work__social .dark {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 300ms ease-in-out;
}

.c-work__social:hover {
  background-color: white;
}

.c-work__social:hover svg path {
  fill: #171b27;
}

.c-work__social:hover .white {
  opacity: 0;
}

.c-work__social:hover .dark {
  opacity: 1;
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}

@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}
