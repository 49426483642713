/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  padding: 8px 0;
  background-color: $color-dark;
}

.c-footer__menu {
  display: block;
  list-style: none;
  text-align: center;
}

.c-footer__menu-item {
  display: inline-block;
  margin: 0;

  @include bp(medium) {
    margin: 0 10px;
  }
}

.c-footer__menu-link {
  display: block;
  padding: 5px;
  font-family: $font-serif;
  font-weight: bold;
  color: $color-blue;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    color: white;
  }

  @include bp(medium) {
    padding: 5px 10px;
  }

  @include bp-down(medium) {
    font-size: 11px;
  }
}
