.c-work.c-newsletter {
  padding-top: 65px;
  padding-bottom: 40px;

  @include bp-down(medium) {
    h2 {
      font-size: 24px;
    }

    p {
      font-size: 13px;
    }
  }
}

.c-newsletter__content {
  p,
  h2 {
    @include bp-down(medium) {
      color: $color-text;
    }
  }

  form {
    position: relative;
    display: flex;
    max-width: 575px;
    margin: 10px auto;
    margin-top: 40px;
    padding-left: 75px;
    background: url('../assets/images/email-icon.png') no-repeat left center;

    @include bp(medium) {
      margin: 30px auto;
      padding: 0 75px;
      background-position: left center;
    }
  }

  .gfield {
    margin: 0;
  }

  /* stylelint-disable */
  .gform_body {
    width: 100%;
  }

  .validation_error {
    display: none;
  }
  /* stylelint-enable */
}

.c-donation__content {
  p,
  h2 {
    text-align: center !important;
    color: white !important;
  }

  form {
    display: block;
    padding: 0;
    background: none;
  }

  /* stylelint-disable */
  .gfield_radio {
    display: flex;
    margin: 0 -10px;
  }

  .gfield_radio li {
    flex: 1;
    position: relative;
    overflow: hidden;
    margin: 0 10px;
    padding: 0;

    input {
      position: absolute;
      left: -999px;
      opacity: 0;
    }

    label {
      @extend .c-btn;
      @extend .c-btn--outline;
      display: block;
      padding: 9px 5px;
      font-size: 13px;
      cursor: pointer;

      @include bp(medium) {
        padding: 13px 30px;
        font-size: 18px;
      }
    }

    input:checked+label {
      background-color: black;
      color: white;
    }
  }

  .gform_wrapper {
    margin-top: 20px;
  }

  .gform_footer {
    text-align: center;
  }

  .gform_button {
    @extend .c-btn--primary;
    width: 210px;
    margin-top: 15px;
  }

  .gfield_total {
    display: none;
  }

  .ginput_amount {
    display: block;
    width: calc(50% - 10px);
    margin-top: 10px;
    margin-left: auto;
    background-color: transparent;
    border: 2px solid white;
    color: white;

    @include bp(medium) {
      width: calc(25% - 15px);
    }
  }

  .ginput_amount::placeholder {
    color: currentColor;
    font-size: 12px;
    font-style: italic;
  }

  .ginput_amount::-webkit-input-placeholder {
    color: currentColor;
    font-size: 12px;
    font-style: italic;
  }

  .ginput_amount::-moz-placeholder {
    color: currentColor;
    font-size: 12px;
    font-style: italic;
  }

  /* stylelint-enable */
}
