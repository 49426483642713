.c-about {
  position: relative;
  padding-top: 60px;
  padding-bottom: 30px;
  overflow: hidden;

  @include bp(large) {
    padding-top: 230px;
    padding-bottom: 130px;
  }
}

.c-about__content {
  position: relative;
  max-width: 90%;
  margin-left: auto;

  @include bp(large) {
    max-width: 45%;
  }
}

.c-about__content-bg {
  content: '';
  position: absolute;
  top: -35px;
  bottom: -50px;
  left: -80px;
  right: 0;
  display: block;
  background-repeat: repeat;
  background-position: left top;
  z-index: 0;

  @include bp(large) {
    left: -100px;
    top: -160px;
    right: -999px;
    right: -30vw;
  }
}

.c-about__content-inner {
  position: relative;
  padding-right: 15px;
  font-size: 15px;
  z-index: 1;

  @include bp(large) {
    padding-right: 0;
  }

  .c-btn {
    margin-top: 15px;
  }
}

.c-about__subtitle {
  font-family: $font-serif;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 2px;
  margin-left: -27px;

  @include bp(large) {
    margin-left: 0;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 9px;
    margin-right: 6px;
    margin-bottom: 2px;
    vertical-align: middle;
    background-color: $color-blue;
  }

  span {
    vertical-align: middle;
  }
}

.c-about__title {
  margin-bottom: 25px;
}

.c-about__img {
  display: block;

  @include bp(large) {
    position: absolute;
  }
}

.c-about__img--1 {
  width: 90%;

  @include bp(large) {
    bottom: 65px;
    left: 0;
    width: auto;
  }
}

.c-about__img--2 {
  width: 50%;
  margin-left: auto;
  margin-top: -80px;

  @include bp(large) {
    bottom: 0;
    right: 50%;
    width: auto;
    margin-left: 0;
    margin-top: 0;
  }
}
