/* ==========================================================================
   #BUTTONS
   ========================================================================== */

.c-btn {
  display: inline-block;
  margin: 0;
  padding: 10px 20px;
  vertical-align: middle;
  font: inherit;
  font-family: $font-serif;
  font-weight: bold;
  line-height: 1.3;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  transition: $global-transition;
}

/* Style variants
   ========================================================================== */

.c-btn--primary {
  background-color: $color-blue;

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    /* [4] */
    color: $color-text;
  }

  &:hover,
  &:focus {
    background-color: white;
  }
}

.c-btn--dark {
  padding: 8px 20px;
  font-size: 11px;
  background-color: $color-dark;

  @include bp(medium) {
    padding: 14px 30px;
    font-size: 13px;
  }

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    /* [4] */
    color: white;
  }

  &:hover,
  &:focus {
    background-color: white;
    color: $color-text;
  }
}

.c-btn--outline {
  padding: 16px 38px;
  background-color: transparent;
  border: 3px solid white;

  &:hover {
    background-color: white;
    color: $color-text;
  }
}

.c-btn--outline-dark {
  background-color: transparent;
  border: 3px solid $color-text;

  &:hover {
    background-color: $color-text;
    color: white;
  }
}

.c-btn--dark-blue {
  padding: 17px 20px;
  font-size: 13px;
  color: $color-blue;
  background-color: $color-dark;

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    /* [4] */
    color: $color-blue;
  }

  &:hover,
  &:focus {
    background-color: $color-white;
    color: $color-blue;
  }
}

.c-btn--read {
  padding: 8px 15px;
  background-color: $color-blue;
  color: white;
  font-size: 13px;
  overflow: hidden;

  @include bp(medium) {
    padding: 12px 18px;
  }

  &:hover,
  &:focus {
    color: white;
    background-color: $color-dark;

    span {
      transform: translateX(50%);
      opacity: 0;
    }

    &:before {
      transform: translateX(-50%);
      opacity: 1;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-100%);
    display: block;
    width: 21px;
    height: 12px;
    margin-top: -6px;
    background: url('../assets/images/arrow-icon.png') no-repeat center center;
    background-size: 21px 12px;
    opacity: 0;
    transition: all 0.3s $menu-transition;
  }

  span {
    display: block;
    opacity: 1;
    transform: translateX(0);
    transition: all 0.3s $menu-transition;
  }
}

.c-btn--read-dark {
  color: $color-text;

  @include bp(medium) {
    padding: 22px 20px;
  }

  &:before {
    background: url('../assets/images/arrow-icon-dark.png') no-repeat center center;
    background-size: 21px 12px;
  }


  &:hover,
  &:focus {
    color: $color-text;
    background-color: white;
  }
}

.c-btn--read-white {
  color: $color-text;
  background-color: white;

  &:before {
    background: url('../assets/images/arrow-icon.png') no-repeat center center;
    background-size: 21px 12px;
  }

  &:hover,
  &:focus {
    color: $color-text;
    background-color: $color-blue;
  }
}
