.c-recent-posts {
  padding: 20px 0;

  @include bp(large) {
    padding: 40px 0;
  }
}

.c-recent-posts__list {
  @include bp(large) {
    display: flex;
  }
}

.c-recent-posts__list-col {
  @include bp(large) {
    width: 50%;
  }

  &-left {
    @include bp(large) {
      padding-right: 20px;
    }
  }

  &-right {
    @include bp(large) {
      padding-left: 20px;
    }
  }

  .c-tease {
    width: 100%;
    margin: 0;
  }
}

.c-recent-posts__list-col-right {
  margin-top: 20px;

  @include bp(large) {
    margin-top: 0;
  }

  .c-tease+.c-tease {
    margin-top: 20px;

    @include bp(large) {
      margin-top: 40px;
    }
  }

  .c-tease__author,
  .c-tease__desc {
    display: none;
  }
}

.c-recent-posts__list-col-left {
  .c-tease {
    height: 100%;

    h4 {
      font-size: 24px;

      @include bp(large) {
        font-size: 40px;
      }
    }
  }

  .c-tease__timestamp {
    @include bp(large) {
      margin-top: 40px;
    }
  }
}
