.c-page__hero {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

  h1 {
    font-weight: bold;

    @include bp(xlarge) {
      font-size: 90px;
    }
  }
}

.c-page__hero-thumbnail {
  color: white;
  text-align: center;
}

.c-page__subtitle {
  max-width: 550px;
  margin: 0 auto;
}

.c-section {
  padding-top: 50px;
  padding-bottom: 50px;

  @include bp(large) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

/* donate */
.c-page__donate {
  &--column {
    max-width: 450px;

    h3 {
      @include bp(large) {
        margin-bottom: 25px;
      }
    }
  }

  .c-work .o-wrapper,
  .c-section .o-wrapper {
    max-width: 1000px;
  }

  .c-section+.c-section {
    padding-top: 0;
  }

  .c-donation {
    padding-top: 0;
    background: none !important;

    &,
    .c-donation__content p,
    .c-donation__content h2 {
      color: $color-text !important;
    }
  }

  .c-donation__content .gfield_radio label {
    @extend .c-btn--outline-dark;
  }
}

.c-page__custom-title {
  padding-right: 20px;

  @include bp(xlarge) {
    min-width: 390px;
  }

  span {
    border-bottom: 3px solid currentColor;
  }
}

.c-page__content-inner {
  @include bp(xlarge) {
    padding-right: 120px;
  }
}

.c-page__tabs {
  padding-top: 35px;
  background-color: $color-dark;

  &,
  a {
    color: white;
  }
}

.c-page__tabs-nav {
  padding-bottom: 35px;
  border-bottom: 1px solid #f7f7f7;
}

.c-page__tabs-nav-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;

  @include bp(medium) {
    justify-content: space-between;
  }

  &:after {
    display: none !important;
  }
}

.c-page__tabs-nav-link {
  display: flex;
  align-items: center;
  padding: 15px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: $font-serif;
  font-weight: bold;
  letter-spacing: 2px;

  @include bp(medium) {
    padding: 0;
  }

  &:hover {
    color: $color-blue;
  }

  img {
    height: 45px;
  }

  .img-active {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: $global-transition;
  }

  &.active,
  &:hover {
    color: $color-blue;

    .img-active {
      opacity: 1;
    }
  }
}

.c-page__tabs-nav-icons {
  position: relative;
  display: block;
  margin-right: 20px;
}

.c-page__tabs-arrow {
  display: none;

  @include bp(medium) {
    display: block;
    width: 20px;
    height: 10px;
  }

  img {
    display: block;
  }
}

.c-page__tabs-tab {
  display: none;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 35px;

  @include bp(medium) {
    flex-wrap: nowrap;
  }

  @include bp(large) {
    padding-top: 100px;
  }

  &.active {
    display: flex;
  }
}

.c-page__tabs-tab--left {
  margin-bottom: 20px;
  text-align: center;

  img {
    margin-top: 15px;

    @include bp(large) {
      margin-top: 35px;
    }
  }
}

.c-page__donate {
  .c-donation__js {
    h4 {
      font-family: $font-sans-serif;

      @include bp(large) {
        margin: 40px 0;
      }
    }

    .gform_footer {
      display: none;
    }

    .gfield_checkbox {
      list-style: none;

      li {
        margin-top: 35px;
        margin-bottom: 0;
        text-align: center;
      }

      input {
        position: absolute;
        left: -999px;
        opacity: 0;
        visibility: hidden;

        &:checked+label:after {
          display: block;
        }
      }

      label {
        position: relative;
        padding-left: 40px;

        &:before,
        &:after {
          content: '';
          position: absolute;
          display: block;
        }

        &:before {
          left: 0;
          top: 50%;
          width: 18px;
          height: 18px;
          margin-top: -9px;
          border: 3px solid $color-dark;
        }

        &:after {
          display: none;
          top: calc(50% - 3px);
          left: 4px;
          width: 21px;
          height: 17px;
          background: url('../assets/images/icon-tick.png') no-repeat center center;
          background-size: contain;
          transform: translateY(-50%);
        }
      }
    }

    .ginput_amount {
      border-color: $color-dark;
      color: $color-dark;
    }
  }

  .c-donation__one-time {
    &.hide {
      display: none;
    }

    .gform_footer {
      display: block;
    }

    .gform_body {
      display: none;
    }
  }
}

.c-page__donate-form {
  padding-bottom: 30px;

  &.hide {
    display: none;
  }

  @include bp(medium) {
    padding-bottom: 50px;
  }

  @include bp(xlarge) {
    padding-bottom: 100px;
  }

  hr {
    margin-bottom: 30px;
    border: 1px solid #e3e3e5;
    background-color: none;
  }

  .o-wrapper--full {
    max-width: 1200px;
  }

  .gfield_required {
    display: none;
  }

  .gform_fields {
    @include bp(medium) {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
    }
  }

  .gfield {
    margin-bottom: 20px;

    @include bp(medium) {
      width: 100%;
      padding: 5px;
    }
  }

  .gfield.half {
    @include bp(medium) {
      width: 50%;
    }
  }

  .gfield:nth-child(1) .gfield_label {
    display: block;
    font-weight: bold;
  }


  .gform_wrapper {
    max-width: 695px;
    margin: 0 auto;
  }

  input,
  select {
    border-radius: 2px;
    border: 1px solid #e3e3e5;
    background-color: $color-bg;

    @include bp(medium) {
      height: 52px;
    }
  }

  .gfield_html {
    margin-bottom: 15px;
    padding: 0;
    border-bottom: 1px solid #e3e3e5;

    @include bp(medium) {
      margin: 0 5px;
      margin-bottom: 25px;
    }
  }

  .form-details {
    position: relative;
    display: inline-block;
    padding: 14px 20px 10px;
    font-weight: bold;
    background-color: white;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border: 1px solid #e3e3e5;
    border-bottom: none;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      display: block;
      width: 100%;
    }

    &:after {
      bottom: -1px;
      height: 2px;
      background-color: white;
    }

    &:before {
      top: 0;
      height: 3px;
      background-color: $color-blue;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
  }

  .gform_footer {
    margin-top: 35px;
    text-align: center;
  }

  .gform_button {
    @extend .c-btn;
    @extend .c-btn--primary;
    display: inline-block;
    border: none;

    &:hover {
      background-color: $color-dark;
      color: white;
    }
  }

  &--content {
    padding: 20px;
    background-color: white;

    @include bp(xlarge) {
      padding: 60px;
    }
  }
}
