.c-hero {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;

  @include bp-down(medium) {
    padding-top: 0 !important;
  }

  @include bp(600px) {
    background-position: center bottom;
  }
}

.c-hero__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 180px;
  padding-bottom: 70px;

  @include bp(medium) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 100px;
    padding-bottom: 0;
  }
}

.c-hero__content {
  position: relative;
  margin-left: auto;
  padding-right: 60px;
  color: white;
  text-align: right;

  @include bp(medium) {
    width: 80%;
    padding-right: 116px;
  }

  @include bp(large) {
    width: 61%;
    padding-right: 116px;
  }
}

.c-hero__text {
  max-width: 415px;
  margin-left: auto;
  font-size: 13px;
  padding-bottom: 15px;

  @include bp(medium) {
    font-size: 17px;
  }

  form {
    position: relative;
    display: flex;
    margin-top: 30px;
  }

  .gfield {
    margin: 0;
  }

  /* stylelint-disable */
  .gform_body {
    width: 100%;
  }

  .validation_error {
    display: none;
  }

  /* stylelint-enable */
}

.c-hero__socials {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  transform-origin: 100% 100%;
  transform: rotate(-90deg) translateX(100%);
  color: $color-text;
  font-weight: bold;

  p {
    margin: 0;
    padding: 5px 0;

    &:after {
      content: '';
      display: inline-block;
      width: 60px;
      height: 2px;
      margin-left: 20px;
      margin-right: 20px;
      vertical-align: middle;
      background-color: $color-text;

      @include bp-between(medium, large) {
        width: 30px;
      }
    }
  }
}

.c-hero__socials-list {
  display: flex;
  margin: 0;
  list-style: none;

  li {
    margin: 0;
  }
}

.c-hero__socials-link {
  position: relative;
  display: block;
  padding: 5px 8px;

  @include bp(medium) {
    padding: 3px 14px;
  }

  @include bp-between(medium, large) {
    padding: 5px;
  }

  .dark {
    transition: $global-transition;
  }

  .white {
    position: absolute;
    left: 50%;
    top: 50%;
    opacity: 0;
    transition: $global-transition;
    transform: translate(-50%, -50%);
  }

  svg {
    width: 20px;
    height: 20px;
    transform: rotate(90deg);
  }

  svg path {
    fill: $color-text;
    filter: none;
    transition: $global-transition;
  }

  &:hover {
    .white {
      opacity: 1;
    }

    .dark {
      opacity: 0;
    }

    svg path {
      fill: white;
      filter: none;
    }
  }
}

.c-hero--post {
  margin-top: 84px;
  padding-bottom: 35%;
}
