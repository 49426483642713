.c-columns {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;

  @include bp(medium) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.c-columns__item {
  width: 230px;
  text-align: center;
  font-size: 19.2px;
  margin: 0 auto;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  @include bp(medium) {
    margin: 0;
  }

  &--auto {
    width: auto;
    text-align: left;
  }
}

.c-columns__title {
  margin-bottom: 10px;
  margin-top: 25px;
  text-transform: uppercase;
  color: $color-blue;
  font-family: $font-serif;
}
