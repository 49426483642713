/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

.c-post {
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 17px;
  overflow-x: hidden;

  @include bp(medium) {
    padding-bottom: 50px;
  }

  @include bp(xlarge) {
    padding-bottom: 100px;
  }

  a {
    word-break: break-all;
  }
}

.c-post__title {
  font-weight: bold;

  @include bp(xlarge) {
    font-size: 56px;
  }

  @include bp(full) {
    font-size: 62px;
  }
}

.c-post__tagline {
  @extend h3;
  margin-top: 30px;
  font-weight: bold;

  @include bp(large) {
    font-size: 26px;
  }
}

.c-post__content {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: white;

  &.o-wrapper--supernarrow {
    @include bp(large) {
      max-width: 900px;
      padding-left: 35px;
      padding-right: 35px;
    }
  }

  p {
    font-family: Georgia;
    font-size: 19.2px;
    color: #191919;

    @include bp(large) {
      font-size: 17px;
    }
  }
}

.c-post__meta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  @include bp(xlarge) {
    margin-bottom: 45px;
  }

  .c-tease__category {
    margin-bottom: 0;
  }
}

.c-post__author {
  color: $color-blue;
}

.c-post__timestamp.c-tease__timestamp {
  margin-top: 45px;
  margin-bottom: 30px;
  background: url('../assets/images/clock-icon-dark.png') no-repeat left center;
}

.c-post__thumb {
  width: 100%;
  height: auto;
}

.c-post__nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  a {
    @extend .c-btn;
    @extend .c-btn--primary;
  }
}

.c-post__nav-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e3e3e5;

  @include bp(large) {
    margin-top: 60px;
  }
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

figcaption {
  font-style: italic;
}

.a2a_kit a {
  width: 44px;
  height: 35px;
  margin-left: -1px;
  padding: 0 !important;
  line-height: 35px;
  text-align: center;
  border: 1px solid #e3e3e5 !important;

  &:hover {
    background-color: $color-dark;

    span {
      opacity: 1 !important;
    }

    path {
      fill: white;
    }
  }
}

.video-shortcode {
  max-width: 650px;
  margin: 40px auto;

  .c-tease__thumb,
  .c-tease__thumb--video {
    height: auto;
  }

  .c-tease__thumb--video .c-embed--video {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    z-index: 0;

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .c-tease__thumb--video-overlay {
    height: 100%;
    z-index: 1;
  }

  .c-tease__thumb--video-play {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -25px;
    transform: translateX(-50%);
  }
}

blockquote {
  position: relative;
  max-width: 640px;
  margin: 25px 0;
  padding: 20px 0 20px 45px;

  @include bp(xlarge) {
    padding-left: 130px;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    height: 100%;

    @include bp(xlarge) {
      left: 90px;
    }
  }

  &:before {
    width: 8px;
    background-color: $color-blue;
  }

  &:after {
    width: 25px;
    border-top: 8px solid $color-blue;
    border-bottom: 8px solid $color-blue;
  }

  * {
    font-family: $font-sans-serif;
  }

  *:not(cite) {
    font-weight: bold;
    font-size: 18px;
  }

  cite {
    font-size: 13px;
    color: $color-blue;
    text-transform: uppercase;
    font-style: normal;
  }
}

.c-posts__connected {
  padding-bottom: 30px;

  @include bp(large) {
    padding-bottom: 100px;
  }

  h3 {
    text-transform: uppercase;

    @include bp(xlarge) {
      font-size: 30px;
    }
  }
}

.c-posts__connected-items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
}

.c-posts__connected-item {
  width: 100%;
  padding: 10px 20px;

  @include bp(medium) {
    width: 50%;
  }

  h5 {
    margin: 0;
    line-height: 1.2;
  }

  .c-tease__author {
    margin-top: 5px;
  }

  .img {
    flex: 0 0 150px;
    width: 150px;
    height: auto;
    background-size: cover;
  }
}

.c-posts__connected-item__content {
  display: flex;
  height: 100%;
  padding: 20px;
  text-decoration: none;
  background-color: white;

  &:hover {
    color: $color-text;

    h5 {
      text-decoration: underline;
    }
  }
}

.c-posts__connected-item__text {
  padding-left: 20px;
}
