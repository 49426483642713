.c-posts-scroller {
  padding: 15px 0;

  @include bp(medium) {
    padding: 50px 0;
  }
}

.c-posts-scroller__title {
  margin-bottom: 0;
  text-transform: uppercase;

  @include bp(medium) {
    margin-bottom: 35px;
  }
}

.c-posts-scroller__content {
  padding: 0 15px;

  @include bp(medium) {
    padding: 0;
    white-space: nowrap;
    overflow: auto;
  }

  .c-tease:first-child {
    @include bp(xlarge) {
      margin-left: calc((100vw - 1185px) / 2);
    }
  }

  .c-tease:last-child {
    @include bp(xlarge) {
      margin-right: calc((100vw - 1185px) / 2);
    }
  }

  .c-tease:hover {
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  }

  .c-tease {
    @include bp-down(medium) {
      display: flex;
      align-items: stretch;
    }

    .c-tease__desc,
    .c-tease__author,
    .c-btn {
      display: none;

      @include bp(medium) {
        display: block;
      }
    }
  }

  @include bp-down(medium) {
    .c-tease__content {
      flex: 1;
      padding: 10px 20px;
    }

    .c-tease__thumb--video,
    .c-tease__thumb {
      flex: 0 0 150px;
      width: 100px;
      height: auto;
      order: 2;
    }

    .c-tease__thumb--video-overlay {
      height: 100%;
    }

    h4 {
      margin-bottom: 10px;
      font-size: 14px;
    }
  }

  @include bp-down(small) {
    .c-tease__thumb--video,
    .c-tease__thumb {
      flex: 0 0 100px;
    }
  }
}

.c-posts-scroller--theologie {
  .c-btn {
    background-color: $color-sea;

    &:hover,
    &:focus {
      background-color: $color-dark;
    }
  }

  .c-tease__thumb--video-play:hover,
  .c-posts-scroller__title a:hover,
  h4 a:hover {
    color: $color-sea;
  }

  .simplebar-scrollbar:before {
    background-color: $color-sea;
  }

  .c-tease__thumb--video-play:hover p:after {
    border-color: transparent transparent transparent $color-sea;
  }

  .c-tease__thumb--video-play circle {
    stroke: $color-sea;
  }
}


.c-posts-scroller--geschiedenis {
  .c-btn {
    background-color: $color-dark;

    &:hover,
    &:focus {
      background-color: $color-blue;
    }
  }

  .c-tease__thumb--video-play:hover,
  .c-posts-scroller__title a:hover {
    color: $color-dark;
  }

  .simplebar-scrollbar:before {
    background-color: $color-dark;
  }

  .c-tease__thumb--video-play:hover p:after {
    border-color: transparent transparent transparent white;
  }

  .c-tease__thumb--video-play circle {
    stroke: white;
  }
}

.c-posts-scroller__mobile {
  border-top: 1px solid #cfdde6;
  text-align: right;

  @include bp(medium) {
    display: none;
  }
}
