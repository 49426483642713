.c-tease {
  position: relative;
  transition: $global-transition;
  overflow: hidden;
  background-color: white;

  .c-btn {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  &:hover .c-tease__bg {
    transform: scale(1.1);
  }
}

.c-tease--post {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  margin: 10px 0;
  white-space: normal;
  vertical-align: top;

  @include bp(medium) {
    width: 350px;
    margin: 0 20px 30px 20px;
  }
}

.c-tease__wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.c-tease__thumb {
  position: relative;
  display: block;
  height: 175px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
}

.c-tease__content {
  position: relative;
  padding: 20px;
  z-index: 1;

  @include bp(medium) {
    padding: 30px;
    padding-bottom: 25px;
  }
}

.c-tease__author {
  margin-top: 50px;
  text-transform: uppercase;
  font-family: $font-serif;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 2px;
}

.c-tease__category {
  min-height: 35px;
  padding-left: 40px;
  padding-top: 13px;
  font-family: $font-serif;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-repeat: no-repeat;
  background-position: left center;

  @include bp-down(medium) {
    margin-bottom: 10px;
  }

  &.c-tease__category--recent {
    padding-top: 8px;

    @include bp(medium) {
      padding-top: 15px;
    }
  }

  @include bp(medium) {
    padding-top: 15px;
  }
}

.c-tease__category--politiek {
  background-image: url('../assets/images/politics-icon-color.png');
}

.c-tease__category--theologie {
  background-image: url('../assets/images/theologie-icon-color.png');
}

.c-tease__category--identiteit {
  background-image: url('../assets/images/identiteit-icon-color.png');
}

.c-tease__category--geschiedenis {
  background-image: url('../assets/images/geschiedenis-icon-color.png');
}

.c-tease__category--recent {
  background-image: url('../assets/images/recent-icon-white.png');
}

.c-tease__thumb--video {
  position: relative;
  height: 175px;
  background-color: black;

  .c-embed {
    display: none;

    @include bp(medium) {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .c-embed>* {
    width: 100%;
    height: 100%;
  }
}

.c-tease__thumb--video-overlay {
  @include bp(medium) {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
    padding: 10px;

    &.hide {
      display: none;
    }
  }
}

.c-tease__thumb--video-play {
  display: none;

  @include bp(medium) {
    position: relative;
    display: flex;
    align-items: center;
    height: 54px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    color: white;

    p {
      margin: 0;
    }

    p:after {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 10px;
      border-style: solid;
      border-width: 5px 0 5px 6px;
      border-color: transparent transparent transparent white;
      transition: $global-transition;
    }

    &:hover p:after {
      border-color: transparent transparent transparent $color-blue;
    }

    svg {
      position: absolute;
      left: 100%;
      top: 50%;
      margin-top: -28px;
      margin-left: -31px;
    }

    circle {
      stroke: $color-blue;
      transform-origin: 50% 50%;
      transform: rotate(-149deg);
    }
  }
}

.c-tease__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  background-size: cover;
  z-index: 0;
  transition: all 0.8s $menu-transition;
}

.c-tease__recent {
  position: relative;
  height: 100%;

  @include bp(medium) {
    padding: 30px 60px 45px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
  }

  &:before {
    @extend .c-thumb__decorator;
  }

  &,
  h4,
  h4 a {
    color: white;
  }

  h4 a:hover {
    color: $color-blue;
  }

  >* {
    position: relative;
    z-index: 1;
  }

  .c-tease__author {
    margin-top: 0;
  }

  h4 {
    font-size: 18px;

    @include bp(medium) {
      font-size: 30px;
    }
  }

  .c-tease__category {
    font-size: 13px;
  }

  .c-tease__author {
    font-size: 11px;
    color: $color-blue;
  }
}

.c-tease__timestamp {
  min-height: 16px;
  margin-top: 20px;
  padding-left: 30px;
  padding-right: 85px;
  text-transform: uppercase;
  font-family: $font-serif;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 2px;
  background: url('../assets/images/clock-icon.png') no-repeat left center;

  @include bp(medium) {
    padding-right: 0;
  }
}

.c-tease__featured {
  background-color: $color-text;

  @include bp(medium) {
    width: 50%;
    padding: 70px 85px 55px;
  }

  &,
  h4,
  h4 a {
    color: white;
  }

  h4 {
    font-size: 24px;

    @include bp(medium) {
      font-size: 40px;
    }
  }

  h4 a:hover {
    color: $color-blue;
  }

  .c-tease__author {
    margin-top: 10px;
    color: $color-blue;
  }

  .c-tease__desc {
    margin-top: 20px;
    margin-bottom: 20px;

    @include bp(medium) {
      margin-top: 30px;
      margin-bottom: 40px;
    }
  }
}

.c-tease__featured-thumb {
  position: relative;
  height: 250px;
  max-height: 55vh;
  overflow: hidden;

  @include bp(medium) {
    width: 50%;
    height: auto;
    max-height: 100vh;
  }

  &:after {
    @extend .c-thumb__decorator;
  }
}

.c-tease__category--featured {
  padding-left: 0;
  background: none;

  &:before {
    content: '';
    display: inline-block;
    width: 30px;
    height: 10px;
    margin-right: 10px;
    margin-top: -2px;
    background-color: $color-blue;
    vertical-align: middle;
  }
}

.c-thumb__decorator {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 55%;
  height: 10px;
  background-color: $color-blue;
  z-index: 1;

  @include bp(medium) {
    width: 30%;
  }
}

.c-tease__popular {
  padding-bottom: 40px;

  @include bp(medium) {
    padding: 40px 25px 25px;
  }

  &:before {
    display: none;
  }

  .c-tease__timestamp {
    padding-right: 0;

    .divider {
      margin: 0 5px;
    }

    .views {
      color: $color-blue;
    }
  }
}

.c-tease__category--popular {
  background-image: url('../assets/images/popular-icon-white.png');
}
