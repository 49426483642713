.c-color {
  &--recent {

    &,
    .c-main-nav__link {
      background-color: $color-text;

      @include bp(large) {
        font-size: 20px;
      }

      color: white;
      border: none;
    }

    &.current-menu-item .c-main-nav__link {
      color: #f7f7f7;
      background-color: $color-text;
    }

    .c-main-nav__link {
      padding-left: 60px;
      background: url('../assets/images/recent-icon-white.png') no-repeat left 15px center;

      @include bp(large) {
        padding-left: 85px;
        background-position: left 25px center;
      }


      &:hover {
        color: #f7f7f7;
        background-color: $color-text;
      }
    }
  }

  &--popular {

    &,
    .c-main-nav__link {
      background-color: #f7f7f7;

      @include bp(large) {
        font-size: 20px;
      }

      color: $color-text;
      border: none;
    }

    &.current-menu-item .c-main-nav__link {
      color: $color-dark-grey;
      background-color: #f7f7f7;
    }

    .c-main-nav__link {
      padding-left: 60px;
      background: url('../assets/images/popular-icon.png') no-repeat left 15px center;

      @include bp(large) {
        padding-left: 85px;
        background-position: left 25px center;
      }


      &:hover {
        color: $color-dark-grey;
        background-color: #f7f7f7;
      }
    }
  }

  &--politics {

    &,
    .c-main-nav__link {
      background-color: $color-blue-dark;

      @include bp(large) {
        font-size: 20px;
      }

      color: white;
      border: none;
    }

    &.current-menu-item .c-main-nav__link {
      color: #f7f7f7;
      background-color: $color-blue-dark;
    }

    .c-main-nav__link {
      padding-left: 60px;
      background: url('../assets/images/politics-icon-white.png') no-repeat left 15px center;

      @include bp(large) {
        padding-left: 85px;
        background-position: left 25px center;
      }


      &:hover {
        color: #f7f7f7;
        background-color: $color-blue-dark;
      }
    }
  }

  &--identity {

    &,
    .c-main-nav__link {
      background-color: $color-blue;

      @include bp(large) {
        font-size: 20px;
      }

      color: white;
      border: none;
    }

    &.current-menu-item .c-main-nav__link {
      color: #f7f7f7;
      background-color: $color-blue;
    }

    .c-main-nav__link {
      padding-left: 60px;
      background: url('../assets/images/identity-icon-white.png') no-repeat left 15px center;

      @include bp(large) {
        padding-left: 85px;
        background-position: left 25px center;
      }


      &:hover {
        color: #f7f7f7;
        background-color: $color-blue;
      }
    }
  }

  &--theology {

    &,
    .c-main-nav__link {
      background-color: $color-sea;

      @include bp(large) {
        font-size: 20px;
      }

      color: white;
      border: none;
    }

    &.current-menu-item .c-main-nav__link {
      color: #f7f7f7;
      background-color: $color-sea;
    }

    .c-main-nav__link {
      padding-left: 60px;
      background: url('../assets/images/theology-icon-white.png') no-repeat left 15px center;

      @include bp(large) {
        padding-left: 85px;
        background-position: left 25px center;
      }


      &:hover {
        color: #f7f7f7;
        background-color: $color-sea;
      }
    }
  }

  &--history {

    &,
    .c-main-nav__link {
      background-color: $color-dark-sea;

      @include bp(large) {
        font-size: 20px;
      }

      color: white;
      border: none;
    }

    &.current-menu-item .c-main-nav__link {
      color: #f7f7f7;
      background-color: $color-dark-sea;
    }

    .c-main-nav__link {
      padding-left: 60px;
      background: url('../assets/images/history-icon-white.png') no-repeat left 15px center;

      @include bp(large) {
        padding-left: 85px;
        background-position: left 25px center;
      }


      &:hover {
        color: #f7f7f7;
        background-color: $color-dark-sea;
      }
    }
  }
}
