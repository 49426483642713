/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1 {
  font-weight: 500;
  font-size: 34px;

  @include bp(large) {
    font-size: 48px;
  }

  @include bp(xlarge) {
    font-size: 63px;
  }
}

h2 {
  font-size: 21px;

  @include bp(large) {
    font-size: 38px;
  }

  @include bp(xlarge) {
    font-size: 48px;
  }
}

h3 {
  font-size: 19px;

  @include bp(large) {
    font-size: 30px;
  }

  @include bp(xlarge) {
    font-size: 36px;
  }
}

h4 {
  font-size: 21px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-serif;
  line-height: 1;

  a {
    text-decoration: none;
  }
}
