/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

$color-hover-active: #f0f4f7;

.c-main-nav__link {
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: $font-serif;
  font-size: 17px;
  font-weight: 700;
  border-bottom: 1px solid $color-border;
  background-color: white;
  transition: $global-transition;

  @include bp(large) {
    padding: 25px 65px;
    font-size: 24px;
  }

  &:hover {
    color: $color-text;
    background-color: $color-hover-active;
  }
}

.current-menu-item .c-main-nav__link {
  background-color: $color-hover-active;
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
}

.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  background: $color-white;
  border-bottom: 1px solid $color-border;
  display: block;
  padding: 0.5em;
  text-decoration: none;
}

.current-menu-item .c-main-nav__sublink {
  border-bottom-color: $color-black;
}


.c-main-nav__toggle {
  display: block;
  position: relative;
  margin-top: 6px;
  user-select: none;
  z-index: 1;

  &:hover > span {
    background-color: $color-text;
  }
}

.c-main-nav__toggle-checkbox {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  -webkit-touch-callout: none;

  &:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: $color-dark;
  }

  &:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  &:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }

  &:checked ~ .c-main-nav {
    transform: translate(-100%, 0);
  }
}

.c-main-nav__toggle > span {
  display: block;
  width: 30px;
  height: 4px;
  margin-bottom: 6px;
  position: relative;
  background: white;
  z-index: 1;
  transform-origin: 4px 0;
  transition: transform 0.5s $menu-transition,
    background 0.5s $menu-transition,
    opacity 0.55s ease;

  &:first-child {
    transform-origin: 0% 0%;
  }

  &:nth-last-child(2) {
    transform-origin: -15% 60%;
  }
}

.c-main-nav {
  position: absolute;
  top: 60px;
  left: 60px;
  width: auto;
  max-height: calc(100vh - 100px);
  overflow: auto;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(0, 0);
  transition: transform 0.5s $menu-transition, opacity 0.5s $menu-transition, top 0.3s $menu-transition, max-height 0.3s $menu-transition;
  border-left: 2px solid $color-ultra-light-grey;

  @include bp(xlarge) {
    left: calc(50vw - 550px);
  }
}

.scrolled .c-main-nav {
  top: 40px;
  max-height: calc(100vh - 80px);

  @include bp-down(medium) {
    height: 100vh;
    max-height: calc(100vh - 40px);
    width: 100vw;
    background-color: white;
  }
}

.c-main-nav__item {
  margin: 0;
  padding: 0;
  padding-right: 10px;

  @include bp(large) {
    padding-right: 0;
  }
}

.c-menu--title {
  pointer-events: none;
}

.c-main-nav__link-text {
  position: relative;
  display: inline-block;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    width: 0;
    height: 1px;
    background-color: currentColor;
    transition: $global-transition;
  }
}

.current-menu-item .c-main-nav__link .c-main-nav__link-text:after,
.c-main-nav__link:hover .c-main-nav__link-text:after {
  width: 100%;
}
