// bootstrap

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.card-body {
  padding-top: 20px;
  padding-bottom: 20px;

  @include bp(large) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

// end bootstrap

.c-career {
  .o-wrapper {
    max-width: 1000px;
  }

  a {
    color: $color-blue;

    &:hover {
      color: $color-text;
    }
  }

  .c-btn {
    width: 100%;
    margin-top: 15px;
    text-align: left;
    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 0;
    text-transform: none;
    color: $color-text;

    @include bp(large) {
      margin-top: 30px;
      font-size: 36px;
    }

    &--blue {
      background-color: $color-blue;
    }
    
    &--water {
      background-color: $color-blue-dark;
    }
    
    &--grey {
      background-color: $color-dark;
      color: white;
    }
    
    &--mint {
      background-color: $color-sea;
    }
  }
}
