/* ==========================================================================
   #SHARED
   ========================================================================== */

/**
 * Shared declarations for certain elements.
 */

address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 15px;
}

dd, ol, ul {
  margin: 0;
}

p:last-child {
  margin-bottom: 0;
}
