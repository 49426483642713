/* stylelint-disable */
.gform_fields {
  display: block;
  margin: 0;
  list-style: none;
}

.gform_validation_container {
  display: none !important;
  position: absolute !important;
  left: -9000px;
}

.gfield_label {
  display: none;
}

input,
textarea,
select {
  border: none;
  outline: none;
  border-radius: 0;
}

.gfield {
  position: relative;

  input,
  textarea,
  select {
    display: block;
    width: 100%;
    padding: 8px 20px;
    font-family: $font-sans-serif;
    font-size: 13px;
    font-weight: bold;

    @include bp(medium) {
      padding: 18px 20px;
    }
  }

  input::placeholder {
    font-family: $font-sans-serif;
    color: $color-text;
  }

  input::-webkit-placeholder {
    font-family: $font-sans-serif;
    color: $color-text;
  }

  input::-moz-placeholder {
    font-family: $font-sans-serif;
    color: $color-text;
  }

  [type="email"] {
    height: 50px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    @include bp-down(medium) {
      height: 30px;
    }
  }
}

.gform_button {
  @extend .c-btn;
  @extend .c-btn--dark;
  height: 50px;
  border: none;
  border-radius: 0;
  outline: none;

  @include bp-down(medium) {
    height: 30px;
  }
}

input.gform_button {
  @include bp(medium) {
    font-size: 13px;
    padding-top: 17px;
    padding-bottom: 17px;
  }
}

.gform_footer {
  position: relative;
}

.gform_ajax_spinner {
  position: absolute;
  right: -25px;
  top: 20px;
}

.validation_message {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

/* stylelint-enable */

section {
  max-width: 100%;
}
