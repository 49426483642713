.c-featured-news {
  padding-bottom: 40px;

  @include bp-down(medium) {
    padding-top: 40px;
  }

  .c-tease {
    width: 100%;
    margin: 0;

    @include bp(medium) {
      display: flex;
    }
  }
}
