.page-contact {
  .c-page__hero {
    text-align: center;
  }

  .c-page__content {
    padding-top: 0;
  }

  .c-page__content .o-wrapper {
    max-width: 1000px;
  }

  .c-page__content {
    .gform_wrapper {
      max-width: 850px;
      margin: 0 auto;
      padding: 15px;
      background-color: white;

      @include bp(large) {
        padding: 30px 65px;
      }
    }

    .gform_title {
      text-transform: uppercase;
    }

    .gform_body {
      margin-top: 15px;

      @include bp(medium) {
        margin-top: 30px;
      }
    }

    .gfield {
      margin-bottom: 10px;

      &:nth-child(1),
      &:nth-child(2) {
        max-width: 300px;
      }

      input,
      textarea {
        border-radius: 2px;
        border: 1px solid #e3e3e5;
        background-color: #f7f7f7;
      }
    }

    .gform_button {
      @extend .c-btn;
      @extend .c-btn--primary;
    }

    .gform_footer:after {
      content: '*Verplichte velden';
      display: block;
      margin-top: 10px;
      font-size: 13px;
    }
  }
}
