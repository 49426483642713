///* ========================================================================
//   #GLOBAL
//   ======================================================================== */
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-radius: 3px !default;
$global-transition: all 300ms ease-in-out !default;
$menu-transition: cubic-bezier(0.77, 0.2, 0.05, 1);

/* Fonts
   ========================================================================== */
@import url('https://fonts.googleapis.com/css?family=Raleway:400,700|Zilla+Slab:400,400i,500,700&display=swap');
$font-sans-serif: 'Raleway', sans-serif, -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
  'Roboto', 'Helvetica Neue', 'Arial', sans-serif !default;
$font-serif: 'Zilla Slab', serif;

/* Breakpoints
   ========================================================================== */

$breakpoints: (
  small: 480px,
  medium: 768px,
  large: 1024px,
  xlarge: 1200px,
  full: 1600px,
);


/* Colors
   ========================================================================== */

// Colors shouldn't be used directly but reassigned
$color-dark: #171b27;
$color-blue: #00cdf2;
$color-blue-dark: #02adf3;
$color-sea: #00d8df;
$color-dark-sea: #313649;

$color-white: #fff;
$color-black: #000;
$color-ultra-light-grey: #f0f4f7;
$color-very-light-grey: #d8dbde;
$color-light-grey: #999;
$color-medium-grey: #666;
$color-dark-grey: #333;

// Text
$color-bg: #f7f7f7;
$color-text: $color-dark;

// Links
$color-link: $color-medium-grey;
$color-hover: $color-blue;

// Borders
$color-border: $color-very-light-grey;

// Fills
$color-fill: $color-very-light-grey;
