/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: $color-text;
  text-decoration: underline;

  &:not(.c-btn) {
    transition: $global-transition;
  }

  &:hover {
    color: $color-hover;
  }
}
