.c-work {
  padding: 70px 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: white;

  @include bp(medium) {
    padding: 100px 0;
  }

  h2 {
    font-size: 36px;

    @include bp(medium) {
      font-size: 48px;
    }
  }

  p {
    max-width: 1060px;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
  }

  a:not(.c-work__social) {
    @extend .c-btn;
    @extend .c-btn--outline;

    color: white;
    font-size: 15px;
    text-decoration: none;

    @include bp(medium) {
      margin-top: 60px;
    }
  }
}

.c-work__socials {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  text-align: center;

  @include bp(medium) {
    margin-top: 0;
  }
}

.c-work__social {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  margin: 10px;
  vertical-align: middle;
  background-color: $color-text;
  text-decoration: none;
  transition: $global-transition;

  &:last-child {
    margin-right: 0;
  }

  &:first-child {
    margin-left: 0;
  }

  svg path {
    fill: white;
    filter: none;
    transition: $global-transition;
  }

  .white {
    transition: $global-transition;
  }

  .dark {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: $global-transition;
  }

  &:hover {
    background-color: white;

    svg path {
      fill: $color-dark;
    }

    .white {
      opacity: 0;
    }

    .dark {
      opacity: 1;
    }
  }
}
